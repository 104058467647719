const onGet = ( status ) => {

    switch ( status ) {
        case 404:
            return {
                status: status,
                message: "recuperare la commessa: la commessa non esiste",
                redirectRoute: '/commesse/'

            }    
        case 422:
            return {
                status: status,
                message: "recuperare la commessa: l' uuid commessa non è corretto",
            } 
        default:
            return null;
    }

}

export default onGet;