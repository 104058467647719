import { useLocation } from "react-router-dom";

export const CurrentRoute = () => {
    const location = useLocation();
    const { pathname } = location;

    let currentRoute = null;

    switch (pathname) {
      case '/':
        currentRoute = 'home';
        break;
      case '/clienti/':
        currentRoute = 'clients';
        break;
      case '/commesse/':
        currentRoute = 'commissions';
        break;
      default:
        currentRoute = 'other';
        break;
    }
    return currentRoute;
}