import axios from 'axios';


export default axios.create({
	baseURL: "https://api.dpc-computer.com/v1",
	// baseURL: "https://api.filippomenis.it/v1",
	// baseURL: "http://localhost:5000/v1",
	headers: {
		"Content-type": "application/json",
	}
});