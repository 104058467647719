import { useState, useEffect, Fragment } from 'react';
import { useHistory } from  "react-router-dom";
import * as Client from '../../api/client';
import clsx from 'clsx';
import { Box,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Fab,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
    Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import ClientDelete from './Client.Delete';
import { motion, AnimatePresence } from 'framer-motion';

import ErrorSwitcher  from '../Error';

const useStyle = makeStyles( ( theme ) => ({
    root: {
        width: 'calc(90% - 2rem)',
        maxHeight: 'calc(100% - 2rem)',
        position: 'relative',
        overflowY: 'auto',
        [theme.breakpoints.down('md')]: {
            width: 'calc(100% - 2rem)',
        },
    },
    cardActions: {
        justifyContent: 'center',
    },
    cardTitle: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1rem',
    },
    Col_1: {
        minWidth: 110,
        paddingLeft: 16,
    },
    Col_2: {
        minWidth: 130,
    },
    Col_3: {
        minWidth: 130,
    },
    Col_4: {
        minWidth: 180,
    },
    Col_Menu: {
        width: 24,
    },
    cellMenu: {
        display: 'flex',
    },
    fab: {
        position: 'fixed',
        bottom: 24,
        right: 24,
        [theme.breakpoints.down('xs')]: {
             width: 40,
             height: 40,
             bottom: 8,
             right: 8,
        },
    },
    tableHeader: {
        fontWeight: 'bold',
    },
    tableRow: {
        cursor: 'pointer',
    },
}))

const Clients = (props) => {

    const { errorState, handleFeedback, searching } = props;

    const classes = useStyle();
    const history = useHistory();

    /**
     * Initial state for delete 
     */
    const isDeleteInitial = {
        status: false,
        id: '',
    };

        /**
     * State - Loader status
     * @return {isLoading} - Show/hide loader
     */
    const [isLoading, setIsLoading] = useState(true);

    /**
     * State - List of client
     * @return {clients} - clients The field used to list the clients
     */
    const [clients, setClients] = useState([]);

    /**
     * State - Pagination
     * @return {page} - Page  the current Page
     * @return {totalPage} - totalPage  the total of pages
     */
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);

    /**
     * State - Sorting
     * @return {sortBy} - sortBy The field used to sort results
     * @return {order}  - Sort order
     */
    const [sortBy, setSortBy] = useState('created_at');
    const [order, setOrder] = useState('DESC');

    /**
     * State - Searching
     * @return {statusCommission} - statusCommission is the current status of the commission
     */
    const [searchClients, setSearchClients] = useState(null);

     /**
     * State - Delete client
     * @return {clients} - clients The field used to list the clients
     */
    const [isDelete, setIsDelete] = useState(isDeleteInitial);

    /**
     *  @function getClientsList
     *  @return {*} getClientsList return list of the clients and the pagination
     */
    const getClientsList = ( fromDelete = false ) => {

        const params =  setSearchParams();
        
        Client.getAll( params )
            .then( response => {
                const { clients, tot_docs } = response.data;

                setClients (clients );

                let total_pages =  Math.ceil( tot_docs / 15 );
                if( !total_pages ) total_pages = 1;
                setTotalPage( total_pages );

                if (page > totalPage) 
                    setPage(totalPage);
                
                setIsLoading(false);

                if( fromDelete ) {
                    const feedback = {
                        status: true,
                        severity: 'success',
                        message:  'Cliente cancellato con successo'
                    }      
                    handleFeedback(feedback); 
                }
            })
            .catch( error => {
                const errorMessage = ErrorSwitcher( error, 'Client.List');
                errorState( errorMessage );
              });
    }

    const setSearchParams = ( ) => {

        let isNewSearch = false;

        if ( Boolean(searching) ) {
            const { value: stringToSearch } = searching;
            setSearchClients(stringToSearch);
            if( stringToSearch !== searchClients ) {
                isNewSearch = true; 
                setPage(1);
            } else {
                isNewSearch = false;
            }
        } else {
            setSearchClients(null);
        }

        return   { 
            field: Boolean(searching) ? searching.field : null,
            limit: 15, 
            offset: isNewSearch ? 0 : parseInt( ( page - 1 ) * 15 ),
            order: order,
            sort_by: sortBy,
            search: Boolean(searching) ? searching.value : null,
        };

    }



    useEffect( () => {   
        setIsLoading(true);
        getClientsList();

        // eslint-disable-next-line
    }, [ page, order, searching, sortBy, totalPage])
 
    /**
     * 
     * @param {*} _ Placeholder for event object
     * @param {*} next The value of the next Page
     */
    const handleChangePage = ( _, next ) => {   
        setPage( next );
    }

    /**
     * @function handleSorting
     * @param {*} fieldToSort is the field to refer the sort
     */
    const handleSorting = ( fieldToSort ) => {
        if( fieldToSort === sortBy ) {
            switch ( order ) {
                case 'ASC':
                    setOrder( 'DESC' );
                    break;
                case 'DESC':
                    setSortBy( 'created_at' );
                    setOrder( 'DESC' );
                    break;
                default:
                    break;
            }
        } else {
            setSortBy( fieldToSort );
            setOrder( 'ASC' );
        }
    }
      
    /**
     * @function deleteClient
     * @param {*} event is used to stop the propagation of event listener
     * @param {*} idClient the id of the client to delete
     */
    const deleteClient = (event , idClient) => {
        event.stopPropagation();
        const info = {
            status: true,
            id: idClient
        }
        setIsDelete({ ...isDelete, ...info });
    }

    /**
     * @function redirect
     * @param {*} id the id of the client details
     */
    const redirect = (id) => {
        history.push(`/clienti/${ id }`);
    }

    /**
     * @function resetInfoToDelete
     * @param {Boolean} real is a real delete or undo the operation
     */
    const resetInfoToDelete = real => {
        setIsDelete(isDeleteInitial);
        if ( real ) {
            setIsLoading(false);
            getClientsList(true);
        }
    }

    /**
     * @function tableRow render the row of the table clients
     */
    const tableRow = () => {    
        return clients.map( (client, index) => {
            return (
                <TableRow className={ classes.tableRow } key={index} hover onClick={ () => redirect(client.id) }>
                    <TableCell align="left" style={{ paddingLeft: 16 }}>{ client.client_code }</TableCell>
                    <TableCell align="left">{ client.first_name }</TableCell>
                    <TableCell align="left">{ client.last_name }</TableCell>
                    <TableCell align="left">{ client.phone }</TableCell>
                    <TableCell align="center">
                    { ( client.is_erasable ) ? (
                        <Tooltip title="Elimina" placement="top">
                            <IconButton id="topbar__userProfile" color="primary" aria-label="logout user" onClick={ ( event ) => deleteClient( event, client.id ) } >
                                <DeleteIcon  fontSize="small"/>
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <IconButton id="topbar__userProfile" color="primary" aria-label="logout user" disabled onClick={ ( event ) => deleteClient( event, client.id ) } >
                                <DeleteIcon  fontSize="small"/>
                        </IconButton>
                    ) }
                        
                    </TableCell>
                </TableRow>
            )
        })      
    }

    /**
     * Framer motion variants
     */

    const loadingState = {
        waiting: { 
            opacity: 0,
            transition: {
                duration: 1,
                ease: 'easeInOut',
            }
        },
        ready: { 
            opacity: 1,
            transition: {
                duration: 0.600,
                ease: 'easeInOut',
            }
        },
    }


    return (
        <Fragment>
            <Card className={classes.root}>
                <AnimatePresence exitBeforeEnter>
                    <Fragment>
                        <CardContent>
                            <Typography variant="h5" className={classes.cardTitle} >
                                <SupervisorAccountIcon /> <Box component="span" marginLeft={2}>Clienti</Box>
                            </Typography>
                            { isLoading ? (
                                <Box display="flex" alignItems="center" component={ motion.div } key="LoaderCardContent" variants={ loadingState }  initial="waiting" animate="ready" exit="waiting">
                                    <CircularProgress />
                                    <Box component="div" children="Recupero lista clienti in corso..." marginLeft={3} />
                                </Box> 
                            ) : (
                            <Fragment>
                                { ( clients.length ) ? 
                                    ( <TableContainer component={ motion.div } key="DataCardContent" variants={ loadingState }  initial="waiting" animate="ready" exit="waiting" style={{ width: '94%', margin: '0 auto' }}>
                                        <Table >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={ clsx(classes.tableHeader, classes.Col_1 )}>Codice</TableCell>
                                                    <TableCell align="left" className={ clsx(classes.tableHeader, classes.Col_2 )}>
                                                        <TableSortLabel
                                                            active={( sortBy === 'first_name' ) ? true : false }
                                                            direction={ order.toLowerCase() }
                                                            hideSortIcon
                                                            onClick={ () => handleSorting('first_name') }
                                                            >
                                                            Nome                                    
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell align="left" className={ clsx(classes.tableHeader, classes.Col_3 )}>
                                                        <TableSortLabel
                                                            active={( sortBy === 'last_name' ) ? true : false }
                                                            direction={ order.toLowerCase() }
                                                            hideSortIcon
                                                            onClick={ () => handleSorting('last_name') }
                                                            >
                                                            Cognome
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell align="left" className={ clsx(classes.tableHeader, classes.Col_4 )}>Telefono</TableCell>
                                                    <TableCell align="center" className={ clsx(classes.tableHeader, classes.Col_5 )}></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>                        
                                                {tableRow()}                          
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    ) : (
                                    <Box component="div"> Non ci sono clienti nella lista</Box>
                                )}
                                { ( clients.length > 0 && totalPage > 1 ) && (
                                    <CardActions className={ classes.cardActions }>
                                        <Pagination 
                                            count={ totalPage } 
                                            page= {page } 
                                            color="primary" 
                                            size="medium" 
                                            onChange={ handleChangePage }
                                            />
                                    </CardActions>
                                )}    
                                <Tooltip title="Aggiungi" placement="left-start">
                                    <Fab color="secondary" size="large" className={ classes.fab } onClick={ () => history.push('/clienti/aggiungi') }>
                                        <AddIcon />
                                    </Fab>    
                                </Tooltip>
                            </Fragment> 

                    )}
                    </CardContent>
                </Fragment>
            </AnimatePresence>
            </Card>
            { isDelete.status && (
                <ClientDelete 
                    { ...props } 
                    info={ isDelete } 
                    resetInfoToDelete={ (real) => resetInfoToDelete(real) } 
                />
            )}        
        </Fragment>
    )
}

export default Clients;