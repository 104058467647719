import  React from  "react";
import { Route, Redirect } from  "react-router-dom";

const PrivateRoute = props => {
    const {component, path, exact, strict, isLogged} = props;

    return isLogged ? 
        ( <Route  strict={ strict } exact={ exact } path={ path } >
            { component }
        </Route> ) :
        <Redirect to={{
            pathname: "/",
            state: { errorAuthorization: true }
        }} />

}
export default PrivateRoute;