const onUpdate = ( status ) => {

    switch ( status ) {
        case 404:
            return {
                status: status,
                message: "aggiornare il cliente: il cliente non esiste",
                redirectRoute: '/clienti/'
            }    
        case 422:
            return {
                status: status,
                message: "aggiornare il cliente: errore nei parametri di richiesta",
            } 
        default:
            return null;
    }

}

export default onUpdate;