import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles( ( theme ) => ({
    dataContainer: {
        width: '100%',
        marginLeft: 0,
        paddingTop: '1rem',
        height: `calc(100% - ${ theme.SIZING.HEIGTH_TOPBAR })`,
        marginTop: theme.SIZING.HEIGTH_TOPBAR,
        background: theme.palette.grey[200],
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${ theme.SIZING.WIDTH_SIDEBAR })`,           
            marginLeft: theme.SIZING.WIDTH_SIDEBAR,          
            transition: theme.transitions.create(['width','margin-left'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            })
        },
        
    },
    fullWidth: {
        width: '100%',
        marginLeft: 0,
        transition: theme.transitions.create(['width','margin-left'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        })
    }
}))

const DataContainer = ({ sidebarState, component }) => {

    const classes = useStyle();

    if (typeof sidebarState === 'undefined') {
        sidebarState = true;
    }

    return (
        <Box component="div" className={ ( sidebarState ) ? classes.dataContainer : clsx( classes.dataContainer, classes.fullWidth ) }>
            { component }
        </Box>
    )
}

export default DataContainer;