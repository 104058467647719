import { useState } from 'react';
import * as Client from '../../api/client';
import { Box,
        Button,
        Dialog,
        DialogActions,
        DialogContent,
        DialogContentText,
        DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorSwitcher  from '../Error';

const useStyle = makeStyles( ( theme ) => ({
    dialog: {
        zIndex: '5000!important',
        '& .MuiDialog-paper': {
            background: '#ffddab',
        },
        '& .MuiBackdrop-root': {
            backdropFilter: 'blur(2px)',
        },
    },
    dialogTitle: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.primary.main,
        }
    },
    dialogContentText: {
        fontSize: '0.75rem',
        color: theme.palette.primary.main,

    },
    dialogActions: {
        justifyContent: 'flex-end',
    },
    button: {
        '&#delete' : {
            backgroundColor: theme.palette.primary.main,
            color: '#ffddab',
            fontWeight: 'normal',
            fontSize: '0.75rem',
        },
        '&#close' : {
            fontWeight: 'normal',
            fontSize: '0.75rem',

        },
    }
}))

const ClientDelete = ( client ) => {

    const classes = useStyle();

    const { info, resetInfoToDelete, handleFeedback, errorState } = client;

    const [modalDelete, setModalDelete] = useState( info.status );

    const handleClose = () => {
        setModalDelete( false );
        resetInfoToDelete( false );
    }

    const deleteClient = () => {
        setModalDelete( false );
        const feedback = {
            status: true,
            severity: 'info',
            message:  'Cancellazione cliente in corso...'
        }      
        handleFeedback(feedback); 
        const { id } = info;
        
        Client.remove( id )
            .then( response => {
                resetInfoToDelete( true );
            })
            .catch( error => {
                const errorMessage = ErrorSwitcher( error, 'Client.Delete');
                errorState( errorMessage );
                resetInfoToDelete( false );
            })           
    }

    return(
        <Dialog
            className={ classes.dialog }
            open={ modalDelete }
            onClose={ handleClose }
        >
            <DialogTitle className={ classes.dialogTitle } id="alert-dialog-title">
                <Box component="span" marginLeft={0}>{ 'Attenzione'}</Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText className={ classes.dialogContentText } id="alert-dialog-description">
                    <Box component="span" >{ 'Sei sicuro di voler cancellare il cliente?'}</Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={ classes.dialogActions }>
                <Button id="delete" onClick={ deleteClient } className={ classes.button }>
                    Cancella
                </Button>
                <Button id="close" onClick={ handleClose } className={ classes.button }>
                    Annulla
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default ClientDelete;