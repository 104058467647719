const onLogin = ( status ) => {

    switch ( status ) {
        case 404:
            return {
                status: status,
                message: "l'utente non esiste",
            }    
        case 422:
            return {
                status: status,
                message: "errore nei parametri di richiesta",
            } 
        default:
            return null;
    }

}

export default onLogin;