const onList = ( status ) => {

    switch ( status ) {
        case 422:
            return {
                status: status,
                message: "recuperare la lista commesse: parametri di richiesta non validi.",
            } 
        default:
            return null;
    }

}

export default onList;