import { useState } from 'react';
import { useHistory } from  "react-router-dom";
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';

const useStyle = makeStyles( ( theme ) => ({
    dialog: {
        zIndex: '5000!important',
        '& .MuiDialog-paper': {
            background: theme.palette.error.main
        },
        '& .MuiBackdrop-root': {
            backgroundColor: 'rgb(245 67 54 / 35%)',
            backdropFilter: 'blur(2px)',
        },
    },
    dialogTitle: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.common.white
        }
    },
    dialogContentText: {
        fontSize: '0.75rem',
        color: theme.palette.common.white,
        '& > span': {
            display: 'block',
            '&:last-of-type': {
                marginTop: 32,
            },
            '&:not(:last-of-type)': {
                marginBottom: 16,
            },
        },
    },
    dialogActions: {
        justifyContent: 'center',
    },
    button: {
        color: theme.palette.common.white,
    }
}))


const ErrorHandler = ( props ) => {

    const history = useHistory();

    const classes = useStyle();

    const { state, closeModal } = props;

    const [open, setOpen] = useState( true );

    const handleClose = () => { 
        const { redirectRoute = null } = state;

        if( Boolean( redirectRoute ))
            history.push( redirectRoute );

        setOpen( false );
        closeModal();
    }

    const ContentText = content => {
        const { status, message } = content;

        return (
            <DialogContentText className={ classes.dialogContentText } id="alert-dialog-description">
                { Boolean(status) && <Box component="span" >{ `Errore ${ status }` }</Box> }
                <Box component="span" >{ `Impossibile ${ message }` }</Box>
                <Box component="span" >{ 'Se il problema persiste, contattare l \' amministratore' }</Box>
            </DialogContentText>
        )
    }

    return(
        <Dialog
            className={ classes.dialog }
            open={ open }
            onClose={ handleClose }
        >
            <DialogTitle className={ classes.dialogTitle } id="alert-dialog-title">
                <ErrorIcon/> <Box component="span" marginLeft={2}>{ 'Attenzione'}</Box>
            </DialogTitle>
            <DialogContent>
                <ContentText { ...state } />
            </DialogContent>
            <DialogActions className={ classes.dialogActions }>
                <Button onClick={handleClose} className={ classes.button }>
                    Chiudi
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ErrorHandler;