import { createMuiTheme } from '@material-ui/core/styles';
import * as PALETTE from './Palette';
import * as SIZING from './Constants';

const DpcTheme = {
    palette: {
        primary: {
            main:  PALETTE.PRIMARY,
        },
        secondary: {
            main: PALETTE.SECONDARY,
        },
    },
    SIZING ,
    overrides: {
        MuiInputLabel: {
            root: {
                fontSize: '0.875rem',
                letterSpacing: '0.0625rem',
                '&.Mui-focused': {
                    color: PALETTE.PRIMARY,
                }
            },          
        },
        MuiSvgIcon: {
            root: {
                '&.input__component': {
                    color: PALETTE.PRIMARY,
                    marginTop: '1rem',
                    marginRight: '0.75rem'
                },
                '&.search__component': {
                    color: PALETTE.PRIMARY,
                    position: 'absolute',
                    top: '0.3rem',
                    right: 0,
                }             
            }
        },
        MuiButton: {
            root: {
                color: PALETTE.PRIMARY,
                fontWeight: 'bold',
                letterSpacing: '0.075rem',
            }
        },
        MuiDrawer: {
            paper: {
                width: 200,
                background: PALETTE.PRIMARY,
                paddingTop: SIZING.START_CONTENT_TOP,
            },
            paperAnchorDockedLeft: {
                borderRight: 'none',
            }
        },
        MuiTableCell: {
            root: {
                padding: '0 0.25rem',
            }
        },
        MuiFormHelperText: {
            root: {
                position: 'absolute',
                bottom: '-1.5rem',

            }
        },
    },

}

const ThemeOverride = createMuiTheme(DpcTheme);

export default ThemeOverride;