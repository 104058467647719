import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import 'fontsource-roboto';
import './App.scss';
import { ThemeProvider } from '@material-ui/core/styles';
import ThemeOverride from './style/material-ui/ThemeOverride';
import App from './App';

ReactDOM.render(
  <ThemeProvider theme={ ThemeOverride }>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ThemeProvider>,
  
  document.getElementById('root')
);

