const onDelete = ( status ) => {

    switch ( status ) {
        case 404:
            return {
                status: status,
                message: "cancellare il cliente: il cliente non esiste",
            }    
        case 409:
            return {
                status: status,
                message: "cancellare il cliente: il cliente è associato ad una o più commesse",
            } 
        case 422:
            return {
                status: status,
                message: "cancellare il cliente: l' uuid cliente non è corretto",
            } 
        default:
            return null;
    }

}

export default onDelete;