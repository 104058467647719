import { useState, useEffect, Fragment, useMemo, useCallback } from 'react';
import clsx from 'clsx';
import { useHistory } from  "react-router-dom";
import debounce from 'lodash.debounce';
import ClientAdd from '../Clients/Client.Add';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import * as Commission from '../../api/commission';
import { motion, AnimatePresence } from 'framer-motion';
import { Box,
         Button,
         Card,
         CardContent,
         CardActions,
         CircularProgress,
         Dialog,
         DialogActions,
         DialogContent,
         DialogContentText,
         DialogTitle,
         FormControl,
         Grid,
         InputLabel,
         List,
         ListItem,
         MenuItem,
         Select,
         Stepper,
         Step,
         StepLabel,
         Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PostAddIcon from '@material-ui/icons/PostAdd';
import SaveIcon from '@material-ui/icons/Save';
import StorageIcon from '@material-ui/icons/Storage';

import { fade } from '@material-ui/core/styles/colorManipulator';

import ErrorSwitcher  from '../Error';

const useStyle = makeStyles( ( theme ) => ({
    root: {
        width: 'calc(90% - 2rem)',
        '&.isModalClient': {
            width: '100%',
        },
        maxHeight: 'calc(100% - 2rem)',
        overflowY: 'auto',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 2rem)',
            height: 'calc(100% - 2rem)',
        },
        '&#loader': {
            minHeight: 'inherit',
        },
        '& .MuiFormLabel-root': {
            fontSize: 22,
            padding: '0 4px',
            background: theme.palette.common.white,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -7px) scale(0.75)',
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: fade(theme.palette.common.black, 0.55),
        }
    },
    button: {
        '&#step-forward' : {
            backgroundColor: theme.palette.primary.main,
            '&.Mui-disabled': {
                backgroundColor: '#1f3c4587',
            },
            '&.secondary': {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.grey[300],
            },
            color: theme.palette.common.white,
            fontWeight: 'normal',
            fontSize: '0.75rem',
        },
        '&#add-client' : {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
            fontWeight: 'normal',
            fontSize: '0.75rem',
            padding: 10,
            margin: '32px auto',
        },
        '&#undo-client' : {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
            fontWeight: 'normal',
            fontSize: '0.75rem',
            padding: 10,
            width: '100%',
            maxWidth: 320,
            [theme.breakpoints.down('sm')]: {
                width: '60%',
                margin: '32px auto',
            },
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                margin: '32px auto',
            },
        },
        '&#save' : {
            backgroundColor: theme.palette.primary.main,
            color: '#ffddab',
            fontWeight: 'normal',
            fontSize: '0.75rem',
        },
        '&#close' : {
            fontWeight: 'normal',
            fontSize: '0.75rem',
        },
    },
    cardTitle: {
        marginBottom: '43px',
    },
    cardActions: {
        justifyContent: 'flex-end',
        padding: '16px 32px',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
        },
    },
    dialog: {
        zIndex: '5000!important',
        '& .MuiDialog-paper': {
            background: '#ffddab',
        },
        '& .MuiBackdrop-root': {
            backdropFilter: 'blur(2px)',
        },
    },
    dialogTitle: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.primary.main,
        }
    },
    dialogContent: {
       padding: 0,
       '&:first-child': {
            paddingTop: 0,
       },
    },
    dropdownListContainer: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    formControl:  {
        '&:not( .last )': {
            marginBottom: 32,
        },
    },
    formControlInput: {
        marginTop: 32,
    },
    gridSecondStep: {
        flexDirection: "row",
        minHeight: 345,
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        },
        '&.clientSelected': {
            border: `1px solid ${ theme.palette.primary.main }`,
            borderRadius: 4,
            padding: '10px 0',
        },
        '& > div': {
            paddingLeft: 16,
            paddingRight: 16,
            transition: 'padding-bottom .3s ease-out',
        },
        '&.clientSelected > div': {
            paddingBottom: 0,
            transition: 'padding-bottom .3s ease-out',
        },
        '& > div:first-of-type': {
            borderRight: `1px solid #1f3c4552`,
            position: 'relative',
            [theme.breakpoints.down('sm')]: {
                borderRight: `unset`,

            },
        },
    },
    icon: {
        marginTop: 16,
    },
    iconField: {
        transform: 'translateX(-8px)',
        marginRight: 8,
    },
    listItem: {
        paddingTop: 8,
        paddingBottom: 8,
        minHeight: 38,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    loader: {
        color: theme.palette.common.white,
    },
    row: {
        marginBottom: '2.125rem',
    },
    stepper: {
        padding: 0,
    },
    textValidatorContainer: {
        paddingRight: 16,
        paddingLeft: 16,
        borderLeft: 'unset',
        [theme.breakpoints.down('sm')]: {
            borderRight: 'unset',
            paddingLeft: 0,
            paddingBottom: 32,
        },
        '&.last': {
            paddingRight: 0,
            paddingLeft: 0,
            marginTop: 0,
            [theme.breakpoints.down('sm')]: {
                paddingRight: 16,
                marginTop: 32,
            },
        },
        '&.client > div': {
            borderTop: 'unset',
            [theme.breakpoints.down('sm')]: {
                borderTop: '0.0625rem solid #1f3c451c',
                paddingTop: 32,
            },
        },
    },
    validatorForm: {
        marginTop: '5rem',
    },
}))

const CommssionAdd = (props) => {

    const classes = useStyle();
    const history = useHistory();

    const { handleFeedback, errorState, isModalClient = false, getClientInfo = null, creationDone} = props;

    //console.log(isModalClient, getClientInfo);

     /**
     * @constructor - The model of the commission
     */
    const CommissionModel = {
        client_id: null,
        os: null,
        hardware_problem: null,
        software_problem: null,
        product_description: null,
        acquired_material: null,
        performed_operation: null,
        material_used: null,
        notes: null
    }

     /**
     * @constructor - The model of the list of the problems
     */
    const ListsProblems = {
        hardware: null,
        software: null,
    }

    /**
     * @constructor - The model of the search of a client
     */
    const searchInitial = {
        field: null,
        value: null
    }

    /**
     * @constructor - The model of the client retrieve
     */
    let clientInfoModel = {
        client_code: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        postal_code: '',
    }

    /**
     * Framer motion variants
     */
    const slideEffect = {
        start: { 
            x: 32,
            opacity: 0,
            transition: {
                duration: 0.3,
                ease: 'easeInOut',
            }
        },
        visible: { 
            x: 0,
            opacity: 1,
            transition: {
                duration: 0.3,
                ease: 'easeInOut',
            }
        },
        hidden: { 
            x: -32,
            opacity: 0,
            transition: {
                duration: 0.3,
                ease: 'easeInOut',
            }
        },
    }

    const slideDropdown = {
        visible: { 
            transform: 'scaleY(1)',
            originY: 0,
            opacity: 1,
            transition: {
                duration:  0.225,
                ease: 'easeInOut',
            }
        },
        hidden: { 
            transform: 'scaleY(0)',
            originY: 0,
            opacity: 0,
            transition: {
                duration:  0.225,
                delay: 0.195,
                ease: 'easeInOut',
            }
        },
    }

    const slideDropdownItem = {
        visible: { 
            y: 0,
            opacity: 1,
            transition: {
                duration:  0.225,
                delay: 0.195,
                ease: 'easeInOut',
            }
        },
        hidden: { 
            y: 8,
            opacity: 0,
            transition: {
                duration:  0.225,
                ease: 'easeInOut',
            }
        },
    }

    const slideVertical = {
        visible: { 
            y: 0,
            opacity: 1,
            transition: {
                duration:  0.225,
                delay: 0.3,
                ease: 'easeInOut',
            }
        },
        hidden: { 
            y: 8,
            opacity: 0,
            transition: {
                duration:  0.225,
                ease: 'easeInOut',
            }
        },
    }

    const slideVerticalDelayed = {
        visible: { 
            y: 0,
            opacity: 1,
            transition: {
                duration:  0.225,
                delay: 0.525,
                ease: 'easeInOut',
            }
        },
        hidden: { 
            y: 8,
            opacity: 0,
            transition: {
                duration:  0.225,
                ease: 'easeInOut',
            }
        },
    }

    const loadingState = {
        waiting: { 
            opacity: 0,
            transition: {
                duration: 1,
                ease: 'easeInOut',
            }
        },
        ready: { 
            opacity: 1,
            transition: {
                duration: 0.600,
                ease: 'easeInOut',
            }
        },
    }

    /**
     * State - Loader status
     * @return {isLoading} - Show/hide loader
     */
    const [isLoading, setIsLoading] = useState(true);

    /**
     * State - Loader status
     * @return {isLoading} - Show/hide loader
     */
    const [isLoadingSearchClient, setIsLoadingSearchClient] = useState(true);

    /**
     * State - Info of a commission
     * @return {commission} - the state used to get 'CommissionModel'
     */
    const [commission, setCommission] = useState( CommissionModel );

     /**
     * State - List Hardware and Software problems
     * @return {problems} - the state used to get the array list of problems
     */
    const [problems, setProblems] = useState( ListsProblems );

    /**
     * State - Info of a commission
     * @return {step} - the state used to handle wizard step
     */
    const [step, setStep] = useState( 0 );

    /**
     * State - Search client
     * @return {search} - the state used to  to handle dropDown menu 
     */
    const [search, setSearch] = useState( searchInitial );

    /**
     * State - Dropdown items
     * @return {dropdownItems} - the state used to handle dropDown Items 
     */
    const [dropdownItems, setDropdownItems] = useState([]);

     /**
     * State - clientInfo
     * @return {clientInfo} - the state used to get 'clientInfoModel'
     */
    const [clientInfo, setClientInfo] = useState(clientInfoModel);

    /**
     * State - Modal
     * @return {modalState} - the state used to handle modal add
     */
    const [modalState, setModalState] = useState( null );

    useEffect( () => {
        if (isModalClient) setClientInfo({ ...clientInfo, ...getClientInfo});
            
        Commission.getProblemsLists()
            .then( response => {
                const { hardware_problems, software_problems } = response.data;
                const lists = {
                    hardware: hardware_problems,
                    software: software_problems,
                }
                setProblems({ ...problems, ...lists });
                const setDefaultSelectValue = {
                    hardware_problem: hardware_problems[0],
                    software_problem: software_problems[0],
                };
                setCommission({ ...commission, ...setDefaultSelectValue });
                setIsLoading(false);
            })
            .catch( error => {
                const errorMessage = ErrorSwitcher( error, 'Commission.Problems');
                errorState( errorMessage );
            })

        // eslint-disable-next-line
    }, [] )

    /**
     * @function handleChangeInput - Update the field of the state 'commission'
     * @return {commission} 
     */
    const handleChangeInput = event => {
        const {name, value } = event.target;

        ( Boolean(value) ) ?
            setCommission({ ...commission, [name]:value }) :
            setCommission({ ...commission, [name]:null });
    }

    /**
     * @function validateFields - A useless function to remove
     * @return {validate}  
     */
    const validateFields = () => {
        console.log('validate');
    }

    /**
     * @function itemsSelect - Handle the select menu in first step wizard
     * @return {MenuItem} - The options of the select
     */
    const itemsSelect = list => {
        if ( list) {
            return list.map( (item, index) => {
                return ( <MenuItem key={index} value={ item }>{ item }</MenuItem> );
            });
        }
        return false; 
    }

     /**
     * @function handleWizard - Handle the steps wizard
     * @return {setStep} - Set the step active
     */
    const handleWizard = () => {
        ( !Boolean( step )) ? setStep( step + 1 ) : setStep( step - 1 );             
    }

     /**
     * @function handleClose - Close the modal for create client
     * @return {setModalState} - Set modal state
     */
    const handleClose = () => {
        setModalState( null )
    }

     /**
     * @function CommissionDetail - The first step of the wizard creation
     * @return {setModalState} - return component of the first step
     */
    const CommissionDetail = () => {
        return (
                <Grid container component={ motion.div } key="firstSTep" variants={ slideEffect } initial="start" animate="visible" exit="hidden">
                    {/* Left side first step */}
                    <Grid container item xs={ 12 }  md={ 6 }  className={ classes.textValidatorContainer } >
                        <Grid item xs={ 12 } className={classes.textValidator}>
                            <FormControl 
                                variant="outlined" 
                                fullWidth 
                                className={classes.formControl}
                            > 
                                <TextValidator  
                                    autoComplete="off"
                                    fullWidth
                                    label="Prodotto *"
                                    InputLabelProps={{ shrink: true }}
                                    name="product_description"
                                    variant="outlined"
                                    placeholder="Inserire descrizione prodotto"
                                    value={( commission.product_description ) ? commission.product_description : '' } 
                                    validators={['required']}
                                    errorMessages={['campo obbligatorio']} 
                                    onChange={ handleChangeInput } 
                                    InputProps={{
                                        startAdornment: (
                                            <DesktopMacIcon fontSize="small" className={ classes.iconField } />
                                        ),
                                    }} 
                                /> 
                                <TextValidator  
                                    autoComplete="off"
                                    fullWidth
                                    label="Sistema operativo"
                                    InputLabelProps={{ shrink: true }}
                                    name="os"
                                    variant="outlined"
                                    placeholder="Inserire sistema operativo installato"
                                    className={ classes.formControlInput }
                                    value={( commission.os ) ? commission.os : '' } 
                                    onChange={ handleChangeInput } 
                                    InputProps={{
                                        startAdornment: (
                                            <StorageIcon fontSize="small" className={ classes.iconField } />
                                        ),
                                    }} 
                                /> 
                                <TextValidator  
                                    autoComplete="off"
                                    fullWidth
                                    label="Materiale acquisito *"
                                    InputLabelProps={{ shrink: true }}
                                    placeholder="Inserire materiale acquisito"
                                    name="acquired_material"
                                    variant="outlined"
                                    className={ classes.formControlInput }
                                    multiline
                                    rows={ 4 }
                                    value={( commission.acquired_material ) ? commission.acquired_material : '' } 
                                    validators={['required']}
                                    errorMessages={['campo obbligatorio']} 
                                    onChange={ handleChangeInput } 
                                /> 
                            </FormControl>
                        </Grid>
                    </Grid>

                    { /* Right Side first step */ }
                    <Grid container item xs={ 12 }  md={ 6 } className={ classes.textValidatorContainer } >
                        <Grid item xs={ 12 } className={classes.textValidator}>
                            <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                <InputLabel 
                                    id="hardware-label" 
                                    children={ <Box component="span" bgcolor="white" paddingX={1}>Problema hardware</Box> }
                                />
                                <Select
                                    labelId="hardware-label"
                                    name="hardware_problem"
                                    value={( commission.hardware_problem ) ?  commission.hardware_problem : "" } 
                                    MenuProps={{
                                        anchorOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                        },
                                        transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                        },
                                        style:{ zIndex: 10000 },
                                        getContentAnchorEl: null
                                    }}
                                    onChange={ handleChangeInput }
                                >
                                { itemsSelect( problems.hardware )}
                                </Select>
                                                    
                            </FormControl>                               
                            <FormControl variant="outlined" fullWidth className={ clsx(classes.formControl, 'last') }>
                                <InputLabel 
                                    id="software-label" 
                                    children={ <Box component="span" bgcolor="white" paddingX={1}>Problema software</Box> }
                                />
                                <Select
                                    labelId="software-label"
                                    name="software_problem"
                                    value={( commission.software_problem ) ?  commission.software_problem : '' } 
                                    MenuProps={{
                                        anchorOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                        },
                                        transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                        },
                                        style:{ zIndex: 10000 },
                                        getContentAnchorEl: null
                                    }}
                                    onChange={ handleChangeInput }
                                >
                                { itemsSelect( problems.software )}
                                </Select>
                            </FormControl>
                            <TextValidator  
                                fullWidth
                                label="Descrizione problemi / Note"
                                InputLabelProps={{ shrink: true }}
                                name="notes"
                                variant="outlined"
                                className={ classes.formControlInput }
                                placeholder="Inserire note"
                                multiline
                                rows={ 4 }
                                value={( commission.notes ) ? commission.notes : '' } 
                                onChange={ handleChangeInput } 
                            />   
                        </Grid>
                    </Grid>                     
                </Grid>
        )
    }

     /**
     * @function searchClient - Handle the search of the client in autocomplete wizard creation
     * @return {setSearch} - set the search params
     * @return {startSearch} - start the search in the next @function startSearch
     */
    const searchClient = event => {
        const {name, value} = event.target;

        const param = {
            field: Boolean(value) ? name : null,
            value: Boolean(value) ? value : null
        }

        if( !Boolean(value) ) 
            setIsLoadingSearchClient(true);

        setSearch({ ...search, ...param }, startSearchDebounced( param ));
    }
    
     /**
     * @function startSearch - Perform the search of the client 
     * @description Condition to start: value != null && value code is a number or value name is min 3 characters (no number)
     * @return {setDropdownItems} - Set array of objects
     */
    const startSearch =  useCallback( param => {
        let start = false;
        const { field, value } = param;

        if ( Boolean( value ) ) {
            const paramQuery = {
                field: ( field === 'code' ) ? 'code' : 'name',
                value: ( field === 'code' ) ? parseFloat( value ) : value,
            }
            if ( field === 'code' ) {

                if ( !Number.isNaN( paramQuery.value ) ) {
                    start = true;
                } else {
                    return false;
                }
                
            } else {
                if ( value.length >= 3 ) {
                    start = true;
                }
            }

            if ( start ) {
                setIsLoadingSearchClient(true);
                Commission.searchClient( paramQuery )
                    .then( response => {
                        setIsLoadingSearchClient(false);
                        setDropdownItems( response.data );
                    })
                    .catch( error => {
                        const errorMessage = ErrorSwitcher( error, 'Client.List');
                        errorState( errorMessage );
                    })
            }
        } else {
            setDropdownItems([]);
        }
        return false;
    }, [errorState])

    const startSearchDebounced = useMemo( () => debounce(
        (param) => startSearch(param), 300), [startSearch] 
    )

    /**
     * @function renderDropdown - Render the risults of the search
     * @description case code: render the ListItem if the search value is a number and at least one object
     * @description case last_name or first_name: render the ListItem if the search value is a string and 
     *              at least one object paired with search field sting
     * @return {ListItem} - ListItem 
     */
    const renderDropdown = () => {

        if( isLoadingSearchClient ) {
            return (
                <ListItem 
                    component={ motion.div } 
                    variants={ slideDropdownItem } 
                    key="dropdownItem" 
                    initial="hidden" 
                    animate="visible" 
                    exit="hidden"
                >
                    <Box display="flex" alignItems="center" component={ motion.div } key="LoaderCardContent" variants={ loadingState }  initial="waiting" animate="ready" exit="waiting">
                        <CircularProgress size={20} className={ classes.loader }/>
                        <Box component="div" fontSize="0.75rem" fontWeight={ 400 } lineHeight={ 1.66 } letterSpacing="0.03333em" children="Ricerca cliente in corso..." marginLeft={3} />
                    </Box>
                </ListItem> 
            )
        }

        /**
         * CASE FIELD = CODE
         */
        if ( search.field === 'code' ) { 
            if ( !isNaN( search.value )) {
                if( dropdownItems.length ) {
                    const { client_code, first_name, id, last_name } = dropdownItems[0];
                    return (
                        <ListItem 
                            button 
                            className={ classes.listItem } 
                            component={ motion.div } 
                            variants={ slideDropdownItem } 
                            key="dropdownItem" 
                            initial="hidden" 
                            animate="visible" 
                            exit="hidden"
                            onClick={ () => getInfoClient(id)}
                         >
                            <Typography variant="caption">{ `${ client_code } - ${ first_name} ${ last_name}`}</Typography>
                        </ListItem> )
                } else { 
                    return (
                        <ListItem 
                            component={ motion.div } 
                            variants={ slideDropdownItem } 
                            key="dropdownItem" 
                            initial="hidden" 
                            animate="visible" 
                            exit="hidden"
                        >
                            <Typography variant="caption">Non ci sono clienti con questo codice</Typography>
                        </ListItem> 
                    )
                }
            } else {
                return false;  
            }

        /**
         * CASE FIELD = LAST_NAME || FIRST_NAME
         */
        } else {
            if ( isNaN( search.value )) {
                if ( dropdownItems.length ) {
                    let pairs = 0;
                    return  dropdownItems.map( (item, index) => {
                        const { matching,first_name, last_name, client_code, id } = item;
                        if (matching === search.field) {
                            pairs ++;
                            return (
                                <ListItem 
                                    button 
                                    className={ classes.listItem } 
                                    component={ motion.div } 
                                    variants={ slideDropdownItem } 
                                    key={ `dropdownItem-${ index }` } 
                                    initial="hidden" 
                                    animate="visible" 
                                    exit="hidden"
                                    onClick={ () => getInfoClient(id)}
                                >
                                    <Typography variant="caption">{ `${ client_code } - ${ first_name} ${ last_name}`}</Typography>
                                </ListItem> 
                            )
                        } 
                        if ( !pairs && index === ( dropdownItems.length - 1 )) {
                            return (
                                <ListItem  component={ motion.div } variants={ slideDropdownItem } key="dropdownItemNothing" initial="hidden" animate="visible" exit="hidden">
                                    <Typography variant="caption">Nessun cliente trovato </Typography>
                                </ListItem> 
                            )
                        }
                        return false;                                     
                    });
                } else {
                    return (
                        <ListItem  component={ motion.div } variants={ slideDropdownItem } key="dropdownItem" initial="hidden" animate="visible" exit="hidden">
                            <Typography variant="caption">Nessun cliente trovato </Typography>
                        </ListItem> 
                    )
                }
                
            } else {
                return false;
            }                  
        }
    }

    /**
     * @function getInfoClient - Get the complete info of the client choosed in renderDropdown
     * @param {id} - id of the client
     * @return {setClientInfo} - Set the state infoClient
     */
    const getInfoClient = id => {
        const params =  { 
            id: id
        };
        Commission.getClientInfo( params )
            .then( response => {
                setClientInfo( {...clientInfo, ...response.data} );
            })
            .catch( error => {
                const errorMessage = ErrorSwitcher( error, 'Client.Details');
                errorState( errorMessage );
            });
    }

    /**
     * @function helperText - Get the complete info of the client choosed in renderDropdown
     * @description case code: check if search value is a number
     * @description case last_name or first_name: check if search value is a string with min length 3
     * @param {element} - the element currently active 
     * @return a string under the element active
     */
    const helperText = element => {
        if ( Boolean( search.field ) && search.field === element ) {
            switch ( element ) {
                case 'code':
                    if ( isNaN (search.value )) {
                        return 'Inserire solo numeri';
                    } else {
                        return false; 
                    }        
                default:
                    if ( isNaN (search.value) ) {
                        if ( search.value.length < 3) {
                            return 'Inserire almeno 3 caratteri'; 
                        } else {
                            return false;
                        }
                    } else {
                        return 'Inserire solo caratteri'; 
                    }  
            }
        } else {
            return false;
        }
    }


     /**
     * @function CommissionDetail - The first step of the wizard creation
     * @description check if a id client is setted and render the component
     * @return {setModalState} - return component of the second step
     */
    const CommissionClient = () => {
        const { address, city, client_code, email, id, first_name,  last_name, phone } = clientInfo;

        return (
            <Fragment>
                <Grid container className={ Boolean( id ) ? clsx('clientSelected', classes.gridSecondStep): classes.gridSecondStep } component={ motion.div } key="secondStep" variants={ slideEffect } initial="start" animate="visible" exit="hidden" >

                { /* left Side second step */ }
                    <AnimatePresence exitBeforeEnter initial={ false }>
                        { Boolean( id ) ? (
                            <Fragment>
                            <Grid container component={ motion.div } justify="center" direction="column" item xs={ 12 } md={ 6 } lg={6} key="RightSideSecondStepText" variants={ slideVertical } initial="hidden" animate="visible" exit="hidden" >
                                <Box component={ motion.div } key="RightSideTitleSecondStepText" variants={ slideVertical } initial="hidden" animate="visible" exit="hidden" position={{ xs: "unset" ,md: "absolute"}} top={0} left={0} right={0}  marginBottom={{ xs: 4, md: 0 }} textAlign={{ xs: 'left', sm:"center" }}>Anagrafica</Box>
                                <Box width={{ xs:"max-content", sm: "60%", md: "auto" }} maxWidth="100%" overflow="auto" marginX={{ xs: 0, sm:"auto" }}>
                                    <Box component="div" color="primary" marginBottom={3} fontSize="1rem" lineHeight="1.5rem">
                                        <Box component="span" display="inline-block" width={82}> { `Codice: `} </Box>
                                        <Box component="span" fontWeight="bold" paddingLeft={2}> { client_code } </Box>
                                    </Box>
                                    <Box component="div" color="primary" whiteSpace="nowrap"marginBottom={3} fontSize="1rem" lineHeight="1.5rem">
                                        <Box component="span" display="inline-block" width={82}> { `Nome: `} </Box>
                                        <Box component="span" fontWeight="bold" paddingLeft={2}> { first_name } </Box>
                                    </Box>
                                    <Box component="div" color="primary" whiteSpace="nowrap"fontSize="1rem" lineHeight="1.5rem">
                                        <Box component="span" display="inline-block" width={82}> { `Cognome: `} </Box>
                                        <Box component="span" fontWeight="bold" paddingLeft={2}> { last_name } </Box>
                                    </Box>
                                </Box>
                            </Grid> 
                            </Fragment>
                        ) : (
                            <Grid component={ motion.div } className={ classes.addClientSecondStep } item xs={ 12 } md={ 6 } key="RightSideSecondStepInput" variants={ slideVertical } initial="hidden" animate="visible" exit="hidden">
                                <Box component="div" display="flex" flexDirection="column" alignItems="center" >                                    
                                    <span> Aggiungi cliente </span>
                                    <Button 
                                        id="add-client" 
                                        color="primary" 
                                        className={ classes.button } 
                                        disabled={ (  commission.product_description && commission.acquired_material ) ? false : true}
                                        startIcon={ <PersonAddIcon fontSize="small"/> }
                                        onClick={ () => setModalState('add-client') }
                                    >
                                        <Box component="span">Aggiungi</Box>
                                </Button> 
                                </Box>
                                
                            </Grid>
                        )}
                    </AnimatePresence>

                    { /* right Side second step */ }
                    <Grid container justify="center" item xs={ 12 } md={ 6 } lg={6} className={ clsx('client', classes.textValidatorContainer) } >
                        <AnimatePresence exitBeforeEnter initial={ false }>
                            { Boolean( id ) ? (
                                    <Grid container component={ motion.div } justify="center" direction="column" key="RightSideSecondStepText" variants={ slideVertical } initial="hidden" animate="visible" exit="hidden" style={{ position: 'relative' }}>
                                        <Box component={ motion.div } key="RightSideTitleSecondStepText" variants={ slideVertical } initial="hidden" animate="visible" exit="hidden" position={{ xs: "unset" ,md: "absolute"}} top={0} left={0} right={0}  marginBottom={{ xs: 4, md: 0 }} textAlign={{ xs: 'left', sm:"center" }}>Recapito e contatti</Box>
                                        <Box width={{ xs:"max-content", sm: "60%", md: "auto" }} maxWidth="100%" overflow={{xs:"auto", sm:"unset", md:"auto"}} marginX={{ xs: 0, sm:"auto" }}>
                                            <Box component="div" color="primary" whiteSpace="nowrap"marginBottom={3} fontSize="1rem" lineHeight="1.5rem" zIndex={1}>
                                                {/* <HomeIcon fontSize="small" /> */}
                                                <Box component="span" display="inline-block" width={75} >{ `Indirizzo:` }</Box>
                                                <Box component="span" paddingLeft={2} fontWeight="bold">{ ` ${  address ? address : 'Non disponibile' }` }</Box>
                                            </Box>
                                            <Box component="div" whiteSpace="nowrap"marginBottom={3} fontSize="1rem" lineHeight="1.5rem"  zIndex={1}>
                                                {/* <LocationCityIcon fontSize="small" /> */}
                                                <Box component="span" display="inline-block" width={75} >{ `Città:` }</Box>
                                                <Box component="span" paddingLeft={2} fontWeight="bold">{ ` ${  city ? city : 'Non disponibile' }` }</Box>                                        
                                            </Box>
                                            <Box component="div" whiteSpace="nowrap"marginBottom={3} fontSize="1rem" lineHeight="1.5rem"  zIndex={1}>
                                                {/* <MailIcon fontSize="small" /> */}
                                                <Box component="span" display="inline-block" width={75} >{ `Email:` }</Box>
                                                <Box component="span" paddingLeft={2} fontWeight="bold">{ ` ${  email ? email : 'Non disponibile' }` }</Box>                                        
                                                
                                            </Box>
                                            <Box component="div" whiteSpace="nowrap"fontSize="1rem" lineHeight="1.5rem"  zIndex={1}>
                                                {/* <PhoneIcon fontSize="small" /> */}
                                                <Box component="span" display="inline-block" width={75}>{ `Telefono:` }</Box>
                                                <Box component="span" paddingLeft={2} fontWeight="bold">{ ` ${  phone ? phone : 'Non disponibile' }` }</Box>                                        
                                                
                                            </Box>
                                        </Box>
                                        
                                    </Grid>
                                ) : (                           
                                <Box component={ motion.div } width="100%" key="leftSideSecondStepInput" variants={ slideVertical } initial="hidden" animate="visible" exit="hidden" >
                                        <Box textAlign="center">Cerca cliente</Box>
                                        <FormControl fullWidth className={ clsx(classes.formControl, 'last') }>
                                            <Box component="div" marginTop={{ xs: 4, MD: 0 }}>
                                                <TextValidator 
                                                    autoComplete="off" 
                                                    fullWidth
                                                    label="Codice cliente"
                                                    InputLabelProps={{ shrink: true }}
                                                    placeholder="Inserire codice"
                                                    name="code"
                                                    variant="outlined"
                                                    helperText={  helperText('code') }
                                                    value={( search.field === 'code' ) ? search.value : ''} 
                                                    onChange={ searchClient } 
                                                />                                         
                                                { ( search.field === 'code' && !isNaN( search.value ) ) && (
                                                    <Box component={ motion.div } position="absolute" left={0} right={0} zIndex={5}  variants={ slideDropdown } key="dropdown" initial="hidden" animate="visible" exit="hidden">
                                                        <Box component="div"  className={classes.dropdownListContainer}>
                                                            <List style={{ padding: 0}}>
                                                                { renderDropdown() }
                                                            </List>
                                                        </Box>
                                                    </Box>
                                                )}                                        
                                            </Box>
                                            <Box component="div">
                                                <TextValidator  
                                                    autoComplete="off"
                                                    fullWidth
                                                    label="Nome"
                                                    InputLabelProps={{ shrink: true }}
                                                    placeholder="Inserire nome"
                                                    name="first_name"
                                                    variant="outlined"
                                                    helperText={ helperText('first_name') }
                                                    className={ classes.formControlInput }
                                                    value={( search.field === 'first_name' ) ? search.value : ''} 
                                                    onChange={ searchClient } 
                                                /> 
                                                    { ( search.field === 'first_name' && search.value.length >= 3 ) && (
                                                        <Box 
                                                            component={ motion.div }  
                                                            position="absolute" 
                                                            left={0} 
                                                            right={0} 
                                                            zIndex={5} 
                                                            variants={ slideDropdown } 
                                                            key="dropdown" 
                                                            initial="hidden" 
                                                            animate="visible" 
                                                            exit="hidden"
                                                        >
                                                            <Box component="div" className={classes.dropdownListContainer}>
                                                                <List style={{ padding: 0}}>
                                                                    { renderDropdown() }
                                                                </List>
                                                            </Box>
                                                        </Box>
                                                    )}
                                            </Box>
                                            <Box component="div">
                                                <TextValidator  
                                                    fullWidth
                                                    label="Cognome"
                                                    name="last_name"
                                                    InputLabelProps={{ shrink: true }}
                                                    placeholder="Inserire cognome"
                                                    variant="outlined"
                                                    helperText={ helperText('last_name') }
                                                    autoComplete="off"
                                                    className={ classes.formControlInput }
                                                    value={( search.field === 'last_name' ) ? search.value : ''} 
                                                    onChange={ searchClient } 
                                                />                                     
                                                    { ( search.field === 'last_name' && search.value.length >= 3 ) && (
                                                        <Box 
                                                            component={ motion.div } 
                                                            position="absolute"
                                                            left={0} 
                                                            right={0} 
                                                            zIndex={5}  
                                                            variants={ slideDropdown } 
                                                            key="dropdown" 
                                                            initial="hidden" 
                                                            animate="visible" 
                                                            exit="hidden"
                                                            >
                                                            <Box component="div"  className={classes.dropdownListContainer}>
                                                                <List style={{ padding: 0}}>
                                                                    { renderDropdown() }
                                                                </List>
                                                            </Box>
                                                        </Box>
                                                    )}                                
                                            </Box>
                                        </FormControl>
                                    </Box>                           
                            )}
                        </AnimatePresence>
                </Grid>
            </Grid>
            { ( Boolean(id) && !isModalClient ) && (
                <Box marginTop={3} textAlign="center" component={ motion.div }  variants={ slideVerticalDelayed } key="UndoClientButtonContainer" initial="hidden" animate="visible" exit="hidden" >
                    <Button 
                        id="undo-client" 
                        color="primary" 
                        className={ classes.button } 
                        disabled={ (  commission.product_description && commission.acquired_material ) ? false : true}
                        onClick={ () =>  setClientInfo(clientInfoModel, setSearch(searchInitial))}
                    >
                        <Box component="span" >Annulla cliente</Box>
                    </Button> 
                </Box>
            )}
        </Fragment>
        )
    }

     /**
     * @function feedback - Handle feedback from add client response
     * @return {setModalState} - return close modal to add client
     * @return {handleFeedback} - call render snackbar
     * @return {getInfoClient} - get the complete info client
     */
    const getClientId = feed => {
        setModalState(null);
        getInfoClient(feed);
    }

     /**
     * @function createModelCommission - Handle feedback from add client response
     * @return {setCommission} - insert client id in commission state
     * @return {setModalState} - open modal to create
     */
    const createModelCommission = action => {
        const { id } = clientInfo;
        if ( Boolean(id) ) {
            setCommission({ ...commission, client_id: id });
            setModalState( action );
        } else {
            const feedback = {
                status: true,
                severity: 'error',
                message: 'I campi "Prodotto", "Materiale acquisito" e "Cliente" sono obbligatori',
            }
            handleFeedback(feedback);
        }
        
    }

     /**
     * @function create - Create call api
     * @return {setModalState} - close modal to create
     * @return {handleFeedback} - call render snackbar
     * @return {history} - redirect to commissions list
     */
    const create = () => {
        const feedbackLoader = {
            status: true,
            severity: 'info',
            message: 'Creazione commessa in corso...',
        }
        setModalState( null );
        handleFeedback(feedbackLoader);
        Commission.create( commission )
            .then( response => {
                if( response.status === 201) {
                    const { id } = response.data;
                    const feedback = {
                        status: true,
                        severity: 'success',
                        message: 'Commessa creata con successo',
                        payload: {
                            id: id,
                            route: 'commesse'
                        },
                    }
                    handleFeedback(feedback);
                    if( !isModalClient ) {
                        history.push( '/commesse/');       
                    } else {
                        creationDone();
                    }
                }             
            })
            .catch( error => {
                const errorMessage = ErrorSwitcher( error, 'Commission.Add');
                errorState( errorMessage );
            })
    }

    return (
        <Fragment>
            <AnimatePresence exitBeforeEnter>
            { isLoading ? (
                <Card id="loader" className={ classes.root } >
                    <CardContent>
                        <Box display="flex" alignItems="center" component={ motion.div } key="LoaderCardContent" variants={ loadingState }  initial="waiting" animate="ready" exit="waiting">
                            <CircularProgress />
                            <Box component="div" children="Inizializzazione modulo aggiunta commessa..." marginLeft={3} />
                        </Box> 
                    </CardContent>
                </Card>
            ) : (
            <Fragment>
                <Card className={ isModalClient ? clsx('isModalClient',classes.root) : clsx(classes.root) } elevation={ isModalClient ? 0 : 1 } component={ motion.div } key="DataCardContent" variants={ loadingState }  initial="waiting" animate="ready" exit="waiting">
                    <CardContent>
                        <Grid container alignItems="center">
                            <Grid item container alignItems="center" xs={12} md={6} className={classes.cardTitle} >
                                <PostAddIcon />
                                <Typography variant="h5" color="primary" >                           
                                    <Box component="span" paddingLeft={2} >Aggiungi Commessa</Box>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stepper activeStep={ step } alternativeLabel className={classes.stepper} >
                                    <Step key={'commission'}>
                                        <StepLabel>{ 'Scheda di lavoro' }</StepLabel>
                                    </Step>
                                    <Step key={'client'}>
                                    <StepLabel>{ 'Dati cliente' }</StepLabel>
                                    </Step>
                                </Stepper>
                            </Grid>
                        </Grid>
                        
                        <ValidatorForm onSubmit= { validateFields } className={ classes.validatorForm }>
                            <AnimatePresence exitBeforeEnter initial={ false }>
                                { !Boolean( step ) ? CommissionDetail() : CommissionClient() }
                            </AnimatePresence>
                        </ValidatorForm>
                    </CardContent>
                    <CardActions className={ classes.cardActions }>
                        <Button 
                            id="step-forward" 
                            color="primary" 
                            type="submit" 
                            className={ !step ? classes.button : clsx( classes.button, 'secondary') } 
                            disabled={ (  commission.product_description && commission.acquired_material ) ? false : true}
                            onClick={ handleWizard }
                        >
                            { !step ? (
                                <Fragment>
                                    <Box component="span" paddingRight={1}>Dati Cliente</Box>
                                    <ArrowForwardIosIcon fontSize="small" />
                                </Fragment>
                            ) :
                            (
                                <Fragment>
                                    <ArrowBackIosIcon fontSize="small" />
                                    <Box component="span" paddingRight={1}>Scheda Lavoro</Box>
                                </Fragment>
                            )}                                    
                        </Button>
                        { Boolean( step ) && (
                            <Button 
                                id="step-forward" 
                                color="primary" 
                                type="submit" 
                                className={ classes.button } 
                                onClick={ () => createModelCommission('add-commission') }
                            >
                                <Box component="span" paddingRight={1}>Crea</Box>
                                <SaveIcon fontSize="small" />
                        </Button> 
                        )}
                    </CardActions>                 
                </Card>
                <Dialog
                    fullWidth={ true }
                    maxWidth="md"
                    className={ classes.dialog }
                    open={ modalState === 'add-client' }
                    onClose={ handleClose }
                >
                    <DialogContent className={ classes.dialogContent }>
                        <ClientAdd { ...props } isModalCommission={ true } getClientId={ getClientId }/>
                    </DialogContent>
                </Dialog>
                <Dialog
                    className={ classes.dialog }
                    open={ modalState === 'add-commission' }
                    onClose={ handleClose }
                >
                    <DialogTitle className={ classes.dialogTitle } id="alert-dialog-title">
                        <Box component="span" marginLeft={0}>{ 'Attenzione'}</Box>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText className={ classes.dialogContentText } id="alert-dialog-description">
                            <Box component="span" >{ 'Sei sicuro di voler creare la commessa?'}</Box>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={ classes.dialogActions }>
                        <Button id="save" onClick={ create } className={ classes.button }>
                            Crea
                        </Button>
                        <Button id="close" onClick={ handleClose } className={ classes.button }>
                            Chiudi
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>   
            )}
            </AnimatePresence>
        </Fragment>

    )
}

export default CommssionAdd;