import { Fragment } from 'react';
import { NavLink } from "react-router-dom";
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ThemeOverride from "../../style/material-ui/ThemeOverride";
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles( ( theme ) => ({
    sidebarButton: {
        color: theme.palette.grey[200],
        display: 'flex', 
        alignItems:'center',
        width: '100%', 
        padding: '0.5rem 0 0.5rem 1rem',
    }
}));



const Sidebar = props => {

    const { stateSidebar, stateMobileSidebar, closeMobileSidebar } = props;


    const data = [
        { text: 'Clienti', route: '/clienti/', icon: <SupervisorAccountIcon fontSize="small"  />},
        { text: 'Commesse', route: '/commesse/', icon: <AssignmentIcon fontSize="small" />},
    ]

    const classes = useStyle();

    const sidebarItems = () => {
        return (
            <List>
                { data.map( (item, index ) => {
                    return (
                        <ListItem key={index} disableGutters style={{ padding: 0}} >
                            <NavLink
                                to={ item.route }
                                className={ classes.sidebarButton }
                                activeClassName="selected"
                                activeStyle={{
                                    color: ThemeOverride.palette.primary.main,
                                    background: ThemeOverride.palette.grey[200],
                                }}
                                onClick = { closeMobileSidebar }
                                >
                                <Box component="span" fontSize="18px" marginRight={3}> { item.icon } </Box>
                                <Box component="span" fontSize="18px"> { item.text } </Box>
                            </NavLink>
                        </ListItem>
                    )
                }) }
            </List>
        )
    }



    return(
        <Fragment>
            <Hidden smDown >
                <Drawer
                    className={ classes.drawer }
                    variant="persistent"
                    open={ stateSidebar }
                >
                    { sidebarItems() }
                </Drawer>
            </Hidden>
            <Hidden mdUp >
                <Drawer
                    className={ classes.drawer }
                    variant="temporary"
                    open={ stateMobileSidebar }
                    onClose={ closeMobileSidebar }
                >
                    { sidebarItems() }
                </Drawer>
            </Hidden>
        </Fragment>
    
    )
}

export default Sidebar;