import { useState, Fragment } from 'react';
import clsx from 'clsx';
import { useHistory } from  "react-router-dom";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import * as Client from '../../api/client';
import { Box,
         Button,
         Card,
         CardContent,
         CardActions,
         Dialog,
         DialogTitle,
         DialogActions,
         DialogContent,
         DialogContentText,
         Grid,
         Typography,
        } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import HomeIcon from '@material-ui/icons/Home';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ErrorSwitcher  from '../Error';


const useStyle = makeStyles( ( theme ) => ({
    root: {
        width: 'calc(94% - 2rem)',
        maxHeight: 'calc(100% - 2rem)',
        overflowY: 'auto',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 2rem)',
            maxHeight: 'calc(100% - 2rem)',
        },
        '&.isModalCommission': {
            width: '100%',
            maxHeight: '100%',
        },
        '& .MuiFormLabel-root': {
            fontSize: 22,
            padding: '0 4px',
            background: theme.palette.common.white,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -7px) scale(0.75)',
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: fade(theme.palette.common.black, 0.55),
        }
    },
    cardTitle: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2.5rem',
    },
    cardActions: {
        justifyContent: 'flex-end',
        paddingRight: 16,
    },
    row: {
        marginBottom: '2.125rem',
    },
    iconField: {
        transform: 'translateX(-8px)',
        marginRight: 8,
    },
    button: {
        '&#create' : {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            fontWeight: 'normal',
            fontSize: '0.75rem',
        },
        '&#save' : {
            backgroundColor: theme.palette.primary.main,
            color: '#ffddab',
            fontWeight: 'normal',
            fontSize: '0.75rem',
        },
        '&#close' : {
            fontWeight: 'normal',
            fontSize: '0.75rem',

        },
    },
    dialog: {
        zIndex: '5000!important',
        '& .MuiDialog-paper': {
            background: '#ffddab',
        },
        '& .MuiBackdrop-root': {
            backdropFilter: 'blur(2px)',
        },
    },
    dialogTitle: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.primary.main,
        }
    },
    dialogContentText: {
        fontSize: '0.75rem',
        color: theme.palette.primary.main,

    },
    fieldWrapper: {
        padding: '0 16px',
        marginBottom: 0,
        [theme.breakpoints.down('md')]: {
            marginBottom: 24,

        },
        [theme.breakpoints.down('sm')]: {
            padding: 0,

        },
    },
}))

const ClientAdd = (props) => {
    
    const classes = useStyle();
    const history = useHistory();

    const { handleFeedback, isModalCommission, getClientId, errorState } = props;

    /**
     * @constructor - The model of the client
     */
    const clientModel = {
        first_name: '',
        last_name: '',
        email: null,
        phone: '',
        address: null,
        city: null,
        postal_code: null
    }

    /**
     * State - Info of a client
     * @return {clients} - the state used to get 'clientModel'
     */
    const [client, setClient] = useState( clientModel );

    /**
     * State - Info of a client
     * @return {modalUpdate} - the state used to handle modal add
     */
    const [modalUpdate, setModalUpdate] = useState( false );


    /**
     * @function handleChangeInput - Update the field of the state 'client'
     * @return {client} 
     */
    const handleChangeInput = event => {
        const {name, value } = event.target;
        
        ( value ) ?
            setClient({ ...client, [name]:value}) :
            setClient({ ...client, [name]:null});
    }

     /**
     * @function validateFields - Validate the client info 
     * @return {modalUpdate} - Show the modal for update client and set state 'modalUpdate'
     */
    const validateFields = event => {
        event.preventDefault();
        setModalUpdate(true);
    }

     /**
     * @function handleClose - Close the modal for update client
     * @return {modalUpdate} - Set the 'modalUpdate' state to false
     */
    const handleClose = () => {
        setModalUpdate( false )
    }

    /**
     * @function save - Call api create and return a Promise(response)
     * @return {modalUpdate} - Set the 'modalUpdate' state to false
     * @return {getClientInfo} - Call api list to render the change
     * @return {handleFeedback} - Call the snackbar with {feedback} object
     * @return {history} - Redirect to the page of list of clients
     */
    const create = () => {
        setModalUpdate( false );
        const feedbackLoader = {
            status: true,
            severity: 'info',
            message: 'Creazione cliente in corso...',
        }
        handleFeedback(feedbackLoader);
        Client.create( client )
            .then( response => {
                if( response.status === 201) {
                    const { id } = response.data;
                    const feedback = {
                        status: true,
                        severity: 'success',
                        message: 'Cliente creato con successo',
                        payload: {
                            id: id,
                            route: 'clienti'
                        },
                    }
                    handleFeedback(feedback);
                    if ( !isModalCommission ) {
                        history.push( '/clienti/');
                    } else {
                        getClientId( id );
                    }
                }             
            })
            .catch( error => {
                setModalUpdate( false );

                if ( typeof error.response !== 'undefined' ) {
                    const { status: statusError } = error.response;
                    if ( statusError === 422 ) {
                        const feedback = {
                            status: true,
                            severity: 'error',
                            message: `Errore nella creazione del cliente. Email già esistente`,
                        }
                        handleFeedback(feedback);
                        return null;
                    }  
                }  
                const errorMessage = ErrorSwitcher( error, 'Client.Add');
                errorState( errorMessage );
            })
    }

    return (
        <Fragment>
            <Card className={  isModalCommission ? clsx(classes.root, 'isModalCommission') : classes.root } >
                <CardContent>
                    <Typography variant="h5" color="primary" className={classes.cardTitle} >
                        <PersonAddIcon />
                        <Box component="div" marginLeft={2} >Aggiungi Cliente</Box>
                    </Typography>
                    <ValidatorForm onSubmit= { validateFields }>
                        <Grid container className={ classes.row} >
                            <Grid item xs={12} md={6} className={ classes.fieldWrapper} >
                                <TextValidator  
                                    autoComplete="off"
                                    fullWidth
                                    label="Nome *"
                                    InputLabelProps={{ shrink: true }}
                                    name="first_name"
                                    variant="outlined"
                                    placeholder="Inserire nome"
                                    value={ ( client.first_name ) ? client.first_name : '' } 
                                    validators={['required']}
                                    errorMessages={['campo obbligatorio']} 
                                    onChange={ handleChangeInput } 
                                /> 
                            </Grid>
                            <Grid item xs={12} md={6} className={ classes.fieldWrapper} >
                                <TextValidator  
                                    autoComplete="off"
                                    fullWidth
                                    label="Cognome *"
                                    InputLabelProps={{ shrink: true }}
                                    placeholder="Inserire cognome"
                                    name="last_name"
                                    variant="outlined"
                                    value={ ( client.last_name ) ? client.last_name : '' } 
                                    validators={['required']}
                                    errorMessages={['campo obbligatorio']} 
                                    onChange={ handleChangeInput } 
                                /> 
                            </Grid>
                        </Grid>


                        <Grid container className={ classes.row } >
                            <Grid item xs={12} md={6} className={ classes.fieldWrapper} >
                                <TextValidator  
                                    autoComplete="off"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    label="Indirizzo"
                                    name="address"
                                    placeholder="Inserire residenza o domicilio"
                                    variant="outlined"
                                    value={( client.address ) ? client.address : '' } 
                                    onChange={ handleChangeInput } 
                                    InputProps={{
                                        startAdornment: (
                                            <HomeIcon fontSize="small" className={ classes.iconField } />
                                        ),
                                    }} 
                                /> 
                            </Grid>
                            <Grid item xs={12} md={6} className={ classes.fieldWrapper} >
                                <TextValidator
                                    autoComplete="off"  
                                    fullWidth
                                    label="Città"
                                    name="city"
                                    placeholder="Inserire città"
                                    variant="outlined"
                                    value={( client.city ) ? client.city : '' } 
                                    onChange={ handleChangeInput } 
                                    InputProps={{
                                        startAdornment: (
                                            <LocationCityIcon fontSize="small" className={ classes.iconField } />
                                        ),
                                    }} 
                                /> 
                            </Grid>
                        </Grid>

                        <Grid container className={ classes.row } >
                            <Grid item xs={12} md={6} className={ classes.fieldWrapper} >
                                <TextValidator  
                                    autoComplete="off"
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    placeholder="Inserire email"
                                    variant="outlined"
                                    value={( client.email ) ? client.email : '' } 
                                    validators={['isEmail']}
                                    errorMessages={[ 'Inserire un\' email valida' ]} 
                                    onChange={ handleChangeInput } 
                                    InputProps={{
                                        startAdornment: (
                                            <MailIcon fontSize="small" className={ classes.iconField } />
                                        ),
                                    }} 
                                /> 
                            </Grid>
                            <Grid item xs={12} md={6} className={ classes.fieldWrapper} >
                                <TextValidator  
                                    autoComplete="off"
                                    fullWidth
                                    label="Telefono *"
                                    name="phone"
                                    placeholder="Inserire recapito telefonico"
                                    variant="outlined"
                                    value={ ( client.phone ) ? client.phone : '' } 
                                    validators={['required']}
                                    errorMessages={['campo obbligatorio']} 
                                    onChange={ handleChangeInput } 
                                    InputProps={{
                                        startAdornment: (
                                            <PhoneIcon fontSize="small" className={ classes.iconField } />
                                        ),
                                    }} 
                                /> 
                            </Grid>
                        </Grid> 
                        <CardActions className={ classes.cardActions }>
                            <Button id="create" color="primary" type="submit" className={ classes.button }>
                                Crea
                            </Button>   
                        </CardActions>                 
                    </ValidatorForm>
                </CardContent>
            </Card>
            <Dialog
                className={ classes.dialog }
                open={ modalUpdate }
                onClose={ handleClose }
            >
                <DialogTitle className={ classes.dialogTitle } id="alert-dialog-title">
                    <Box component="span" marginLeft={0}>{ 'Attenzione'}</Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={ classes.dialogContentText } id="alert-dialog-description">
                        <Box component="span" >{ 'Sei sicuro di voler creare il cliente?'}</Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={ classes.dialogActions }>
                    <Button id="save" onClick={ create } className={ classes.button }>
                        Crea
                    </Button>
                    <Button id="close" onClick={ handleClose } className={ classes.button }>
                        Chiudi
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default ClientAdd;