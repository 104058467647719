import { Fragment, useState } from "react";
import { useHistory } from  "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from '@material-ui/core/Slide';
import Alert from "@material-ui/lab/Alert";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles( (theme) => ({
    root: {
        zIndex: 10000,
    }
}))

const RenderSnackbar = (props) => { 

    const { feedback, resetFeedback } = props;

    const history = useHistory();

    const classes = useStyle();

    const [open, setOpen] = useState( feedback.status );

    const handleClose = () => { 
        setOpen( false );
        setTimeout( () => {
            resetFeedback();
        }, 195);      
    }

    const { severity, message, payload } = feedback;

    const redirect = param => {
        const { id, route } = param;
        history.push(`/${ route }/${ id }`);
        handleClose();
    }

    return (
        <>
            {/* <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={ open }
                autoHideDuration={ 3000 }
                onClose={ handleClose }
                TransitionComponent={ Slide }
            >
                <Alert 
                    onClose={ handleClose } 
                    variant="filled" 
                    severity={ severity} 
                    className={classes.alert}
                    action={
                        <Fragment>
                            { Boolean(payload) && (
                                <IconButton size="small" aria-label="close" color="inherit" onClick={ () => redirect(payload) }>
                                    <VisibilityIcon fontSize="small" />
                                </IconButton>
                            )}   
                            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Fragment>
                    }
                >
                    {message}
                </Alert>
            </Snackbar> */}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={ open }
                autoHideDuration={ 6000 }
                onClose={ handleClose }
                TransitionComponent={ Slide }
                className={classes.root}
            >
                <Alert 
                    onClose={ handleClose } 
                    variant="filled" 
                    severity={ severity} 
                    className={classes.alert}
                    action={
                        <Fragment>
                            { Boolean(payload) && (
                                <IconButton size="small" aria-label="close" color="inherit" onClick={ () => redirect(payload) }>
                                    <VisibilityIcon fontSize="small" />
                                </IconButton>
                            )}   
                            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Fragment>
                    }
                >
                    {message}
                </Alert>
            </Snackbar>

        </>
    );
};


export default RenderSnackbar;