import { useState, useEffect, Fragment } from 'react';
import { useParams } from  "react-router-dom";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Commissions from '../Commissions/Commissions';
import CommssionAdd from '../Commissions/Commission.Add';
import * as Client from '../../api/client';
import { Box,
        Button,
        Card,
        CardContent,
        CircularProgress,
        Dialog,
        DialogActions,
        DialogContent,
        DialogContentText,
        DialogTitle,
        Divider,
        Fab,
        Grid,
        IconButton,
        Typography,
        Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PostAddIcon from '@material-ui/icons/PostAdd';
import HomeIcon from '@material-ui/icons/Home';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { motion, AnimatePresence } from 'framer-motion';
import { fade } from '@material-ui/core/styles/colorManipulator';

import ErrorSwitcher  from '../Error';

const useStyle = makeStyles( ( theme ) => ({
    root: {
        width: 'calc(94% - 2rem)',
        maxHeight: 'calc(100% - 2rem)',
        overflowY: 'auto',
        position: 'relative',
        [theme.breakpoints.down('md')]:{
            width: 'calc(100% - 2rem)',
        },
        '& .MuiFormHelperText-root': {
            position: 'relative',
            bottom: 0,
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0,
        },
        '& input.Mui-disabled': {
            color: fade(theme.palette.common.black, 0.7),
        },
        '& .MuiFormLabel-root': {
            fontSize: 22,
            padding: '0 4px',
            background: theme.palette.common.white,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -7px) scale(0.75)',
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: fade(theme.palette.common.black, 0.55),
        }
    },
    button: {
        '&#save' : {
            backgroundColor: theme.palette.primary.main,
            color: '#ffddab',
            fontWeight: 'normal',
            fontSize: '0.75rem',
        },
        '&#close' : {
            fontWeight: 'normal',
            fontSize: '0.75rem',

        },
    },
    cardTitle: {
        marginBottom: '1.5rem',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.25rem',
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '4rem',
        },
    },
    cardTitleSection: {
        marginBottom: 20,
        paddingLeft: 16,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },
    dialog: {
        zIndex: '5000!important',
        '&:not(#add-commission) .MuiDialog-paper': {
            background: '#ffddab',
        },
        '& .MuiBackdrop-root': {
            backdropFilter: 'blur(2px)',
        },
    },
    dialogTitle: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.primary.main,
        }
    },
    dialogContentText: {
        fontSize: '0.75rem',
        color: theme.palette.primary.main,

    },
    dialogActions: {
        justifyContent: 'flex-end',
    },
    divider: {
        marginBottom: 20,
    },
    editIcon: {
        fontSize: '1rem',
    },
    fabEditButton: {
        position: 'absolute',
        top: 16,
        right: 16,
        zIndex: 11,
        [theme.breakpoints.down('xs')]: {
            top: 48,
        },
    },
    fabSaveButton: {
        position: 'absolute',
        top: 24,
        right: 24,
        zIndex: 10,
        background: theme.palette.primary.main,
        color: theme.palette.warning.main,
        [theme.breakpoints.down('xs')]: {
            top: 58,
        },
    },
    iconField: {
        transform: 'translateX(-8px)',
        marginRight: 8,
    },
    row: {
        '& div': {
            fontSize: '0.875rem',
            letterSpacing: '1px',
            whiteSpace: 'nowrap',
            [ theme.breakpoints.up('md')]: {
                fontSize: '1rem',
            },
        },
    },
    rowContainer: {
        marginBottom: 26,
        [ theme.breakpoints.up('sm')]: {
            marginBottom: 0,
        },
    },
    rowFirstField: {
        minHeight: 84,
        paddingLeft: 16,
        paddingRight: 16,
        [ theme.breakpoints.up('sm')]: {
            paddingLeft: 16,
        },
    },
    rowSecondField: {
        minHeight: 84,
        paddingLeft: 16,
        paddingRight: 16,
        [ theme.breakpoints.up('sm')]: {
            paddingLeft: 0,
        },
    },
}))

const ClientDetail = ( props ) => {

    const { errorState, handleFeedback } = props;

    const classes = useStyle();
    const { id } = useParams();
    

    let clientInfo = {
        client_code: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        postal_code: '',
    }

    /**
     * State - Loader status
     * @return {isLoading} - Show/hide loader
     */
    const [isLoading, setIsLoading] = useState(true);

    /**
     * State - Loader status
     * @return {isCreated} - A trick to rerender CommissionList
     */
     const [isCreated, setIsCreated] = useState(false);


    /**
     * State - Details of a client
     * @return {clients} - clients The field used to list the clients
     */
    const [client, setClient] = useState( clientInfo );

    const [editState, setEditState] = useState( false );

    const [modalUpdate, setModalUpdate] = useState( null );

    const getClientInfo = ( fromUpdate = false ) => {
        const params =  { 
            id: id
        };
        Client.get( params )
            .then( response => {
                setClient( {...client, ...response.data} );
                setIsLoading(false);
                if( fromUpdate ) {
                    const feedback = {
                        status: true,
                        severity: 'success',
                        message:  'Cliente aggiornato con successo'
                    }      
                    handleFeedback(feedback); 
                }

            })
            .catch( error => {
                const errorMessage = ErrorSwitcher( error, 'Client.Details');
                errorState( errorMessage );
              });
    }

    useEffect( () => {
        getClientInfo();
        
        // eslint-disable-next-line
    }, [])

    /**
     * @function handleEditState
     * @return {editState} - The state used to enable edit state
     * @return {editField} - Set the 'editField' state to null
     */
    const handleEditState = () => {
        setEditState( !editState );
    }

    /**
     * @function handleChangeInput - Update the field of the state 'client'
     * @return {client} 
     */
    const handleChangeInput = (event, field) => {
        const { value } = event.target;
        setClient( { ...client, [field]:value })
    }

     /**
     * @function validateFields - Validate the client info 
     * @return {modalUpdate} - Show the modal for update client and set state 'modalUpdate'
     */
    const validateFields = event => {
        event.preventDefault();
        if( Boolean(client.first_name) && Boolean(client.last_name) && Boolean(client.phone) ) {
            setModalUpdate('update-client');
        } else {
            const feedback = {
                status: true,
                severity: 'error',
                message:  'I campi con \' * \' sono obbligatori'
            }      
            handleFeedback(feedback);   
        }
    }

     /**
     * @function handleClose - Close the modal for update client
     * @return {modalUpdate} - Set the 'modalUpdate' state to false
     */
    const handleClose = () => {
        setModalUpdate( null )
    }

     /**
     * @function save - Call api update and return a Promise(response)
     * @return {editState} - Toggle the 'editState'
     * @return {editField} - Set the 'editField' state to null
     * @return {modalUpdate} - Set the 'modalUpdate' state to false
     * @return {getClientInfo} - Call api list to render the change
     * @return {handleFeedback} - Call the snackbar with {feedback} object
     */
    const save = () => {
        const {client_code, id, is_erasable, ...updateClient} = client;
        setModalUpdate( null );
        const feedback = {
            status: true,
            severity: 'info',
            message:  'Salvataggio modifiche in corso...'
        }      
        handleFeedback(feedback);        
        Client.update( id, updateClient )
            .then( response => {
                if( response.status === 201) {
                    setEditState( !editState );
                    getClientInfo( true );  
                    
                }             
            })
            .catch( error => {
                const errorMessage = ErrorSwitcher( error, 'Client.Update');
                errorState( errorMessage );
            })
    }

    const addCommission = (event, action) => {
        event.preventDefault();
        setModalUpdate(action);
    }

    const commissionCreated = () => {
        setModalUpdate(null);
        setIsCreated( !isCreated );
    }

    
    /**
     * Framer motion variants
     */
    const variants = {
        visible: { 
            x: -70,
            transition: {
                duration: 0.375,
                ease: 'easeInOut',
            }
        },
        hidden: { 
            x: 0,
            transition: {
                duration: 0.375,
                ease: 'easeInOut',
            }
        },
    }

    const variantsPostAddIcon = {
        visible: { 
            x: -140,
            transition: {
                duration: 0.375,
                ease: 'easeInOut',
            }
        },
        hidden: { 
            x: 0,
            transition: {
                duration: 0.375,
                ease: 'easeInOut',
            }
        },
    }

    const loadingState = {
        waiting: { 
            opacity: 0,
            transition: {
                duration: 0.500,
                ease: 'easeInOut',
            }
        },
        ready: { 
            opacity: 1,
            transition: {
                duration: 0.500,
                ease: 'easeInOut',
            }
        },
    }


    return (
        <Fragment>
            <Card className={ classes.root } >
                <AnimatePresence exitBeforeEnter>
                <CardContent>
                    <ValidatorForm  onSubmit={ validateFields } instantValidate={false} >
                        <Typography variant="h5" color="primary" className={classes.cardTitle} >
                            <Box component="span" >Dettaglio Cliente { isLoading ? '' : `cod. ${ client.client_code }`}</Box>
                        </Typography>
                        { isLoading ? (
                            <Box display="flex" alignItems="center" component={ motion.div } key="LoaderCardContent" variants={ loadingState }  initial="waiting" animate="ready" exit="waiting">   
                                <CircularProgress />
                                <Box component="div" children="Recupero dettagli cliente in corso..." marginLeft={3} />
                            </Box>
                        ) : (
                            <Box component={ motion.div } className="data-card-content" key="DataCardContent" variants={ loadingState }  initial="waiting" animate="ready" exit="waiting">
                                <Typography variant="h6" color="primary" className={classes.cardTitleSection} >
                                    <Box component="span" >Anagrafica Cliente</Box>
                                </Typography>
                                <Grid container >                                         
                                    <Grid container className={ classes.row}>
                                        <Grid item xs={12} sm={6} className={ classes.rowFirstField} >
                                            <TextValidator
                                                fullWidth
                                                disabled={ editState ? false : true }
                                                label="Nome *"
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                placeholder="Inserire nome"
                                                InputProps={{
                                                    endAdornment: (
                                                        editState && (
                                                            <Tooltip title="Cancella testo" placement="top">
                                                                <IconButton  color="primary" aria-label="Cancella contenuto del campo" onClick={ () => setClient( { ...client, 'first_name': null }) } >
                                                                    <CloseIcon className={ classes.editIcon} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )
                                                    ),
                                                }}  
                                                value={ Boolean (client.first_name) ? client.first_name : '' }
                                                error={ Boolean (client.first_name) ? false : true}
                                                helperText={ Boolean(client.first_name) ? '' : 'Campo obbligatorio' }
                                                onChange={ (e) => handleChangeInput( e, 'first_name') } 
                                            /> 
                                        </Grid>
                                        <Grid item xs={12} sm={6} className={ classes.rowSecondField} >
                                            <TextValidator  
                                                fullWidth
                                                disabled={ editState ? false : true }
                                                label="Cognome *"
                                                InputLabelProps={{ shrink: true }}
                                                placeholder="Inserire cognome"
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        editState && (
                                                            <Tooltip title="Cancella testo" placement="top">
                                                                <IconButton  color="primary" aria-label="Cancella contenuto del campo" onClick={ () => setClient( { ...client, 'last_name': null }) } >
                                                                    <CloseIcon className={ classes.editIcon} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )
                                                    ),
                                                }} 
                                                value={ Boolean (client.last_name) ? client.last_name : '' }
                                                error={ Boolean (client.last_name) ? false : true}
                                                helperText={ Boolean(client.last_name) ? '' : 'Campo obbligatorio' }
                                                onChange={ (e) => handleChangeInput( e, 'last_name') } 
                                            /> 
                                        </Grid>
                                    </Grid> 
                                </Grid>
                                <Divider className={ classes.divider} variant="fullWidth" />
                                <Grid container className={ classes.rowContainer }>     
                                    <Typography variant="h6" color="primary" className={classes.cardTitleSection} >
                                                <Box component="span" >Recapito</Box>
                                    </Typography>                                    
                                    <Grid container className={ classes.row}>
                                        <Grid item xs={12} sm={6} className={ classes.rowFirstField} >
                                            <TextValidator  
                                                fullWidth
                                                disabled={ editState ? false : true }
                                                label="Indirizzo"
                                                InputLabelProps={{ shrink: true }}
                                                placeholder={ editState ? "Inserire indirizzo" : "" }
                                                variant="outlined"
                                                InputProps={{
                                                    startAdornment: (
                                                        <HomeIcon fontSize="small" className={ classes.iconField } />
                                                    ),
                                                    endAdornment: (
                                                        editState && (
                                                            <Tooltip title="Cancella testo" placement="top">
                                                                <IconButton  color="primary" aria-label="Cancella contenuto del campo" onClick={ () => setClient( { ...client, 'address': null }) } >
                                                                    <CloseIcon className={ classes.editIcon} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )
                                                    ),
                                                }} 
                                                value={ Boolean (client.address) ? client.address : '' }
                                                onChange={ (e) => handleChangeInput( e, 'address') } 
                                            /> 
                                        </Grid>
                                        <Grid item xs={12} sm={6} className={ classes.rowSecondField} >
                                            <TextValidator  
                                                fullWidth
                                                disabled={ editState ? false : true }
                                                label="Città"
                                                InputLabelProps={{ shrink: true }}
                                                placeholder={ editState ? "Inserire città" : "" }
                                                variant="outlined"
                                                InputProps={{
                                                    startAdornment: (
                                                        <LocationCityIcon fontSize="small" className={ classes.iconField } />
                                                    ),
                                                    endAdornment: (
                                                        editState && (
                                                            <Tooltip title="Cancella testo" placement="top">
                                                                <IconButton  color="primary" aria-label="Cancella contenuto del campo" onClick={ () => setClient( { ...client, 'city': null }) } >
                                                                    <CloseIcon className={ classes.editIcon} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )
                                                    ),
                                                }} 
                                                value={ Boolean (client.city) ? client.city : '' }
                                                onChange={ (e) => handleChangeInput( e, 'city') } 
                                            /> 
                                        </Grid>        
                                    </Grid> 
                                </Grid>
                                <Divider className={ classes.divider} variant="fullWidth" />
                                <Grid container >       
                                    <Typography variant="h6" color="primary" className={classes.cardTitleSection} >
                                        <Box component="span" >Contatti</Box>
                                    </Typography>                                  
                                    <Grid container className={ classes.row}>
                                        <Grid item xs={12} sm={6} className={ classes.rowFirstField} >
                                            <TextValidator  
                                                fullWidth
                                                disabled={ editState ? false : true }
                                                label="Email"
                                                InputLabelProps={{ shrink: true }}
                                                placeholder={ editState ? "Inserire email" : "" }
                                                variant="outlined"
                                                InputProps={{
                                                    startAdornment: (
                                                        <MailIcon fontSize="small" className={ classes.iconField } />
                                                    ),
                                                    endAdornment: (
                                                        editState && (
                                                            <Tooltip title="Cancella testo" placement="top">
                                                                <IconButton  color="primary" aria-label="Cancella contenuto del campo" onClick={ () => setClient( { ...client, 'email': null }) } >
                                                                    <CloseIcon className={ classes.editIcon} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )
                                                    ),
                                                }} 
                                                value={ Boolean (client.email) ? client.email : '' }
                                                onChange={ (e) => handleChangeInput( e, 'email') } 
                                            /> 
                                        </Grid>
                                        <Grid item xs={12} sm={6} className={ classes.rowSecondField} >
                                            <TextValidator  
                                                fullWidth
                                                disabled={ editState ? false : true }
                                                label="Telefono *"
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                placeholder="Inserire telefono"
                                                InputProps={{
                                                    startAdornment: (
                                                        <PhoneIcon fontSize="small" className={ classes.iconField } />
                                                    ),
                                                    endAdornment: (
                                                        editState && (
                                                            <Tooltip title="Cancella testo" placement="top">
                                                                <IconButton  color="primary" aria-label="Cancella contenuto del campo" onClick={ () => setClient( { ...client, 'phone': null }) } >
                                                                    <CloseIcon className={ classes.editIcon} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )
                                                    ),
                                                }} 
                                                error={ Boolean (client.phone) ? false : true}
                                                helperText={ Boolean(client.phone) ? '' : 'Campo obbligatorio' }
                                                value={ Boolean (client.phone) ? client.phone : '' }
                                                onChange={ (e) => handleChangeInput( e, 'phone') } 
                                            /> 
                                        </Grid> 
                                    </Grid>
                                </Grid>
                                <Divider className={ classes.divider} variant="fullWidth" />
                                <Grid container>
                                    <Commissions 
                                        { ...props } 
                                        clientId={ id }
                                        isCreated={ isCreated }
                                    />
                                </Grid>
                                <Tooltip title={ editState ? "Disabilita modifiche" : "Abilita modifiche"} placement="top">
                                    <Fab color="secondary" className={ classes.fabEditButton } aria-label="Abilita modifiche utente" onClick={ handleEditState }>
                                        { editState ?  <LockOpenIcon />: <LockIcon />}
                                    </Fab>
                                </Tooltip>
                                <AnimatePresence exitBeforeEnter>
                                    { editState && (
                                        <>
                                            <Tooltip title="Crea commessa" placement="top">
                                                <Fab 
                                                    color="secondary" 
                                                    size="small" 
                                                    component={ motion.button } 
                                                    variants={ variantsPostAddIcon }  
                                                    initial="hidden" 
                                                    animate="visible" 
                                                    exit="hidden" 
                                                    className={ classes.fabSaveButton } 
                                                    aria-label="crea commessa associata al cliente" 
                                                    onClick={ (event) => addCommission( event, 'add-commission' ) } 
                                                >
                                                    <PostAddIcon />
                                                </Fab>
                                            </Tooltip>   
                                            <Tooltip title="Salva" placement="top">
                                                <Fab 
                                                    color="secondary" 
                                                    size="small" 
                                                    type="submit" 
                                                    component={ motion.button } 
                                                    variants={ variants }  
                                                    initial="hidden" 
                                                    animate="visible" 
                                                    exit="hidden" 
                                                    className={ classes.fabSaveButton } 
                                                    aria-label="salva modifiche cliente"
                                                >
                                                    <SaveIcon />
                                                </Fab>
                                            </Tooltip>  
                                        </>      
                                    )}  
                                </AnimatePresence>     
                            </Box>           
                        )}
                        </ValidatorForm>
                    </CardContent>
                </AnimatePresence>
            </Card>
            <Dialog
                fullWidth={ true }
                maxWidth="md"
                className={ classes.dialog }
                id="add-commission"
                open={ modalUpdate === 'add-commission' }
                onClose={ handleClose }
            >
                <DialogContent className={ classes.dialogContent }>
                    <CommssionAdd 
                        { ...props } 
                        isModalClient={ true } 
                        getClientInfo={ client } 
                        creationDone={ commissionCreated } 
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                className={ classes.dialog }
                open={ modalUpdate === 'update-client' }
                onClose={ handleClose }
            >
                <DialogTitle className={ classes.dialogTitle } id="alert-dialog-title">
                    <Box component="span" marginLeft={0}>{ 'Attenzione'}</Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={ classes.dialogContentText } id="alert-dialog-description">
                        <Box component="span" >{ 'Sei sicuro di voler aggiornare le informazioni del cliente?'}</Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={ classes.dialogActions }>
                    <Button id="save" onClick={ save } className={ classes.button }>
                        Salva
                    </Button>
                    <Button id="close" onClick={ handleClose } className={ classes.button }>
                        Chiudi
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default ClientDetail;

