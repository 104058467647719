import {
  Fragment, 
  useState,
  
} from 'react';
import {
  Switch,
  Route,
  useHistory,
  Redirect,
} from "react-router-dom";
import Clients from './components/Clients/Clients';
import ClientDetail from './components/Clients/Client.Detail';
import ClientAdd from './components/Clients/Client.Add';
import Login from './components/Login';
import Commissions from './components/Commissions/Commissions';
import CommissionDetail from './components/Commissions/Commission.Detail';
import CommissionAdd from './components/Commissions/Commission.Add';
import PrivateRoute from './components/PrivateRoute';
import Topbar from './components/Template-parts/Topbar';
import { CurrentRoute } from './components/Template-parts/Utils';
import DataContainer from './components/Template-parts/DataContainer';
import ErrorHandler from './components/Clients/ErrorHandler';
import RenderSnackbar from './components/Template-parts/Render.Snackbar';


const App = () => {

  const history = useHistory();

  const routePath = CurrentRoute();

  const token = localStorage.getItem('token');

  /**
   * State - Login
   * @return {errorState} - Error catched in query functions
   */
   const [isLogged, setIsLogged] = useState( token );
  
  /**
   * State - Error Handler
   * @return {errorState} - Error catched in query functions
   */
  const [errorState, setErrorState] = useState( null );

   /**
   * State - Search of clients
   * @return {searchClients} - Field and value to search
   */
     const [searchClients, setSearchClients] = useState( null );

        /**
   * State - Search of clients
   * @return {searchClients} - Field and value to search
   */
         const [searchCommissions, setSearchCommissions] = useState( null );

    /**
   * State - Feedback Handler
   * @return {errorState} - feedback return the result of operations ( snackbar )
   */
  const [feedback, setFeedback] = useState({
    status: false,
    severity: '',
    message: '',
    payload: '',
  });

  const [stateSidebar, setStateSidebar] = useState();

//   useEffect( () => {   
   
 
//     // eslint-disable-next-line
// }, [])


  /**
   * 
   */
  const login = (token) => {
    const { jwt } = token;
    localStorage.setItem('token', jwt);
    setIsLogged(jwt);
    history.push('/commesse/');

  }

  const logout = () => {
    localStorage.removeItem('token');
    setIsLogged(null);
    history.push('/');
  }

  const getStateSidebar = status => {
    setStateSidebar(!status);
  }

  const handleError = ( error ) => {  
    setErrorState( error );
  }

  const handleCloseModalError = () => {
    setErrorState(null);
  }
  
  const resetFeedback = () => {
    setFeedback({
      status: false,
      severity: '',
      message: '',
      payload: '',
    });
  }

  const handleFeedback = feedback => {
    setFeedback({ ...feedback });
  }

  const searchActive = searchParam => {

    if (Boolean(searchParam)) {
      const { requestBy } = searchParam;
      switch (requestBy) {
        case 'clients':
          setSearchClients( searchParam );
          break;
        case 'commissions':
          setSearchCommissions( searchParam );
          break;     
        default:
          break;
      }
    } else {
      setSearchClients( searchParam );
      setSearchCommissions( searchParam );
    }
  }

  return (
    <Fragment>
      { ( Boolean(isLogged) && routePath !== 'home') && 
          <Topbar 
            handleAuthenticationState = { logout } 
            currentRoute = { routePath }
            getStateSidebar = { getStateSidebar }
            token = {isLogged}
            searching = { (param) => searchActive(param) }
          />           
      }
      <Switch>
        <Route exact path="/">
          <Login 
            errorState = { handleError }
            handleAuthenticationState = { (token) => login(token) }
            logout = { logout } 
          />
        </Route>
        {/* 
          Commission routes 
        */}
        <PrivateRoute 
          component = {
            <DataContainer 
              component = {
                <Commissions 
                  errorState = { handleError }  
                  handleFeedback={ handleFeedback }
                  searching={ searchCommissions }
                /> } 
              sidebarState = { stateSidebar }
              />
          } 
          path="/commesse/"
          exact
          strict
          isLogged = { Boolean(isLogged) }
        />
         <PrivateRoute 
            component = { 
              <DataContainer 
                component={ <CommissionAdd errorState = { handleError } handleFeedback={ handleFeedback } /> } 
                sidebarState = { stateSidebar }
              /> 
            } 
            path="/commesse/aggiungi"
            exact
            strict
            isLogged = {Boolean(isLogged) }
        />
        <PrivateRoute 
          component = { 
            <DataContainer 
              component={ <CommissionDetail errorState = { handleError } handleFeedback={ handleFeedback }/> } 
              sidebarState = { stateSidebar }
            /> 
          } 
          path="/commesse/:id"
          exact
          strict
          isLogged = { Boolean(isLogged) }
        />
        {/* 
          Client Routes 
        */}
        <PrivateRoute 
          component = {
            <DataContainer 
              component={
                <Clients
                  errorState = { handleError }  
                  handleFeedback={ handleFeedback } 
                  searching={ searchClients }
                /> } 
              sidebarState = { stateSidebar }
              />
          } 
          path="/clienti/"
          exact
          strict
          isLogged = { Boolean(isLogged) }
        />
        <PrivateRoute 
            component = { 
              <DataContainer 
                component={ <ClientAdd errorState = { handleError } handleFeedback={ handleFeedback } /> } 
                sidebarState = { stateSidebar }
              /> 
            } 
            path="/clienti/aggiungi"
            exact
            strict
            isLogged = { Boolean(isLogged) }
        />
        <PrivateRoute 
          component = { 
            <DataContainer 
              component={ <ClientDetail errorState = { handleError } handleFeedback={ handleFeedback }/> } 
              sidebarState = { stateSidebar }
            /> 
          } 
          path="/clienti/:id"
          exact
          strict
          isLogged = { Boolean(isLogged) }
        />
        <Route path="*">
          <Redirect exact to={{
            pathname: "/",
            state: { error404: true }
            }} />
        </Route>
      </Switch>
      { Boolean( errorState ) && (
          <ErrorHandler state={ errorState } closeModal={ handleCloseModalError } />
      )}
      { feedback.status && (
          <RenderSnackbar feedback={ feedback } resetFeedback={ resetFeedback } />
      )}
    </Fragment>
      
  );
}


export default App;
