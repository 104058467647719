import { useState, useEffect, Fragment } from 'react';
import { useHistory } from  "react-router-dom";
import { format } from 'date-fns';
import * as Commission from '../../api/commission';
import clsx from 'clsx';
import { Box,
        Button,
        Card,
        CardActions,
        CardContent,
        Chip,
        CircularProgress,
        Collapse,
        Dialog,
        DialogActions,
        DialogContent,
        DialogTitle,
        Fab,
        FormControl,
        Grid,
        IconButton,
        InputLabel,
        MenuItem,
        Select,
        Table,
        TableBody,
        TableCell,
        TableContainer,
        TableHead,
        TableRow,
        Typography,
        Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import AddIcon from '@material-ui/icons/Add';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import GetAppIcon from '@material-ui/icons/GetApp';
import HistoryIcon from '@material-ui/icons/History';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import { motion, AnimatePresence } from 'framer-motion';
import CommissionDelete from './Commission.Delete';

import ErrorSwitcher  from '../Error';

const useStyle = makeStyles( ( theme ) => ({
    root: {
        width: 'calc(90% - 2rem)',
        maxHeight: 'calc(100% - 2rem)',
        position: 'relative',
        overflowY: 'auto',
        [theme.breakpoints.down('md')]: {
            width: 'calc(100% - 2rem)',
        },
        '&.client': {
            width: '100%',
            boxShadow: 'unset',
        }
    },
    button: {
        background: theme.palette.common.white,
        color: theme.palette.primary.main,
    },
    cardTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    cardActions: {
        justifyContent: 'center',
    },
    chip: {
        '&.warning': {
            background: theme.palette.warning.light,
            '& > svg': {
                color: '#96651c'
            },
        },
        '&.info': {
            background: theme.palette.info.light,
            '& > svg': {
                color: '#0062b1',
            },
        },
        '&.success': {
            background: theme.palette.success.light,
            '& > svg': {
                color: '#316932',
            },
        },
        '&.error': {
            background: theme.palette.error.light,
            '& > svg': {
                color: '#6b3434',
            },
        },
        '&.urgent': {
            background: theme.palette.error.dark,
            '& > span': {
                color: theme.palette.common.white,
                fontSize: 12,
                letterSpacing: 1,
            },
        },
        '&.delete': {
            fontSize: 12,
        },
    },
    Col_1: {
        minWidth: 100,
        paddingLeft: 16,
    },
    Col_2: {
        minWidth: 130,
    },
    Col_2_client: {
        minWidth: 145,
    },
    Col_2_urgent_flag: {
        minWidth: 110,
    },
    Col_3: {
        minWidth: 130,
    },
    Col_4: {
        minWidth: 130,
    },
    Col_Menu: {
        width: 24,
    },
    cellMenu: {
        display: 'flex',
    },
    DialogActions: {
        justifyContent: 'center',
    },
    dialogTitle: {
        textAlign: 'center',
    },
    fab: {
        position: 'fixed',
        bottom: 24,
        right: 24,
        [theme.breakpoints.down('xs')]: {
             width: 40,
             height: 40,
             bottom: 8,
             right: 8,
        },
    },
    filtersContainer: {
        margin: '1rem 0',
    },
    inputLabel: {
        background: theme.palette.common.white,
    },
    optionMenu: {
        color: theme.palette.common.white,
        '&:hover': {
            background: theme.palette.primary.light,
        },
    },
    tableHeader: {
        fontWeight: 'bold',
    },
    tableRow: {
        cursor: 'pointer',
    },
}))

const Commissions = ( props ) => {

    const { errorState, handleFeedback, clientId, isCreated, searching } = props;

    const classes = useStyle();
    const history = useHistory();

    const isClientDetail = Boolean(clientId);

    /**
     * @constructor - Array of commission status 
     */
    const STATUS_LIST = [ 'any','processing', 'created', 'processed', 'rejected'];

    /**
     * Initial state for delete 
     */
    const isDeleteInitial = {
        status: false,
        id: '',
    };

    /**
     * State - Loader status
     * @return {isLoading} - Show/hide loader
     */
    const [isLoading, setIsLoading] = useState(true);

    /**
     * State - List of client
     * @return {commissions} - commissions The field used to list the commissions
     */
    const [commissions, setCommissions] = useState([]);

    /**
     * State - Pagination
     * @return {page} - Page  the current Page
     * @return {totalPage} - totalPage  the total of pages
     */
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);

    /**
     * State - Filtering
     * @return {statusCommission} - statusCommission is the current status of the commission
     */
    const [filterBy, setFilterBy] = useState(null);

     /**
     * State - Searching
     * @return {statusCommission} - statusCommission is the current status of the commission
     */
      const [searchCommissions, setSearchCommissions] = useState(null);

    /**
     * State - Delete commission
     * @return {isDelete} - commission info to delete
     */
    const [isDelete, setIsDelete] = useState(isDeleteInitial);

     /**
     * State - Active Accordion of history transitions of commission state
     * @return {activeRow} - clients The field used to list the clients
     */
    const [activeRow, setActiveRow] = useState(null);

      /**
     * State - Active Accordion of history transitions of commission state
     * @return {activeRow} - clients The field used to list the clients
     */
    const [filters, setFilters] = useState(false);


    /**
     *  @function getClientsList
     *  @return {*} getClientsList return list of the clients and the pagination
     */
    const getCommissionsList = ( fromDelete = false ) => {

        const params =  setSearchParams();

        Commission.getAll( params )
            .then( response => {
                const { commissions, tot_docs } = response.data;
                
                setCommissions (commissions );

                let total_pages = Math.ceil( tot_docs / 15 );
                if( !total_pages ) total_pages = 1;
                setTotalPage( total_pages );
                
                if (page > totalPage) {
                    setPage(totalPage);
                }

                setIsLoading(false);

                if( fromDelete ) {
                    const feedback = {
                        status: true,
                        severity: 'success',
                        message:  'Commessa cancellata con successo'
                    }      
                    handleFeedback(feedback); 
                }
            })
            .catch( error => {
                const errorMessage = ErrorSwitcher( error, 'Commission.List');
                errorState( errorMessage );
              });
    }

    const setSearchParams = ( ) => {

        let isNewSearch = false;

        if ( Boolean(searching) ) {
            const { value: stringToSearch } = searching;
            setSearchCommissions(stringToSearch);
            if( stringToSearch !== searchCommissions ) {
                isNewSearch = true; 
                setPage(1);
            } else {
                isNewSearch = false;
            }
        } else {
            setSearchCommissions(null);
        }

        return  { 
            client_id: isClientDetail ? clientId : null,
            limit: 15, 
            offset: isNewSearch ? 0 : parseInt( ( page - 1 ) * 15 ),
            search: Boolean(searching) ? searching.value : null,
            status: (filterBy === 'any') ? null : filterBy,
            summary: true,
        };

    }


    useEffect( () => {   
        setIsLoading(true);
        getCommissionsList();

        // eslint-disable-next-line
    }, [filterBy, isCreated, page, searching, totalPage])


    /**
     * 
     * @param {*} _ Placeholder for event object
     * @param {*} next The value of the next Page
     */
    const handleChangePage = ( _, next ) => {   
        setPage( next );
    }

    /**
     * @function redirect
     * @param {*} id the id of the client details
     */
    const redirect = (id) => {
        history.push(`/commesse/${ id }`);
    }


    const openStoric = ( event, row ) => {
        event.stopPropagation();
        setActiveRow( row );
    }


    /**
     * @function deleteCommission
     * @param {*} event is used to stop the propagation of event listener
     * @param {*} idClient the id of the client to delete
     */
    const deleteCommission = (event , idCommission) => {
        event.stopPropagation();
        const info = {
            status: true,
            id: idCommission
        }
        setIsDelete({ ...isDelete, ...info });
    }

    /**
     * @function resetInfoToDelete
     * @param {Boolean} real is a real delete or undo the operation
     */
    const resetInfoToDelete = real => {
        setIsDelete(isDeleteInitial);
        if ( real ) {
            setIsLoading(false);
            getCommissionsList( true );
        } 
    }

    const statusLabel = status => {
        switch (status) {
            case 'processed':
                return ( <Chip icon={ <CheckCircleIcon fontSize="small" />} label="Chiusa" className={ clsx('success', classes.chip) }/>) 
            case 'created':
                return ( <Chip icon={ <InfoIcon fontSize="small" />} label="Aperta" className={ clsx('info', classes.chip) }/>)
            case 'processing':
                return ( <Chip icon={ <BuildRoundedIcon fontSize="small" />} label="In lavorazione" className={ clsx('warning', classes.chip) }/>)
            case 'rejected':
                return ( <Chip icon={ <ErrorIcon fontSize="small" />} label="Rifiutata" className={ clsx('error', classes.chip) }/>)
            case 'any':
                return ( <Chip label="Mostra tutte" className={ classes.chip }/>)
            default:
                break;
        }
    }

    const getDates = summary => {
        let dates = {
            created: null,
            processing: null,
            processed: null,
            rejected: null
        }

        const statusList = STATUS_LIST.map( (statusCurrent, index) => {
            return summary.filter( (element) => {
                return element.status === statusCurrent;
            })
        })
        
        statusList.map( (el) => {
            const length = el.length;
            if ( length ) {
                const { status, date } = el[ length-1 ];
                dates = {...dates, [status]: format( new Date(date), "dd/MM/yyyy") }
                return dates;
            }
            return false;
        })
        return dates;

    }

    const generateCommission = ( event, id ) => {
        event.stopPropagation();
        Commission.generatePdf( String(id) )
            .then( response => {
                const file = new Blob(
                    [response.data], 
                    {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                var link = document.createElement("a");
                link.href = fileURL;
                link.setAttribute("download", `copia_cliente.pdf`);
                link.click();
            })
            .catch( error => {
                const errorMessage = ErrorSwitcher( error, 'Commission.GeneratePdf');
                errorState( errorMessage );
            })
    }


    /**
     * @function tableRow render the row of the table clients
     */
    const tableRow = () => {    
        return commissions.map( (commission, index) => { 

            const { code, first_name, id, is_erasable, is_urgent, is_paid, last_name, status, summary  } = commission;

            const dates = getDates(summary);


            return (
                <Fragment key={index}>
                    <TableRow className={ classes.tableRow }  hover onClick={ () => redirect(id) }>
                        <TableCell align="left" style={{ paddingLeft: 16 }}> { `C${code}` }</TableCell>
                        <TableCell 
                            align="center"
                            children={ is_urgent ? <Chip label="Urgente" size="small" className={ clsx('urgent', classes.chip) }/> : '' }
                        />
                        { !isClientDetail ? (
                            <Fragment>
                                <TableCell align="left">{ `${ first_name } ${ last_name }`}</TableCell>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <TableCell align="left">{ Boolean(dates.created) ? dates.created : '' }</TableCell>
                                <TableCell align="left">{ Boolean(dates.processing) ? dates.processing : '' }</TableCell>
                                <TableCell align="left">{ Boolean(dates.processed) ? dates.processed : '' }</TableCell>
                                <TableCell align="left">{ Boolean(dates.rejected) ? dates.rejected : '' }</TableCell>
                            </Fragment>
                        )}
                        <TableCell align="left">
                            <Box component="span" fontSize={14} fontWeight="bold" letterSpacing={2}>{ `${ is_paid ? 'PAGATO' : '' }`}</Box>
                            
                        </TableCell>
                        <TableCell align="left">{ statusLabel( status ) }</TableCell>
                        <TableCell align="center">
                            <Tooltip title="Storico" placement="top">
                                <IconButton id={`commissions__accordionTableRow_${code}`} color="primary" aria-label="Visualizza storico transazioni di stato" onClick={ (event) => openStoric(event, index) } >
                                    <HistoryIcon fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                            { ( is_erasable ) ? (
                                <Tooltip title="Elimina" placement="top">
                                    <IconButton id={`commissions__delete_true_${code}`} color="primary" aria-label="Elimina commessa" disabled={ ( is_erasable ) ? false : true } onClick={ ( event ) => deleteCommission( event, id ) }  >
                                        <DeleteIcon  fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <IconButton id={`commissions__delete_false_${code}`} color="primary" aria-label="Elimina commessa" disabled={ ( is_erasable ) ? false : true } onClick={ ( event ) => deleteCommission( event, id ) }  >
                                    <DeleteIcon  fontSize="small"/>
                                </IconButton>
                            ) }
                            
                        </TableCell>
                        <TableCell align="center">
                            <Tooltip title="Scarica" placement="top">
                                <IconButton id={`commissions__generatePdf_${code}`} color="primary" aria-label="Visualizza transazioni di stato" onClick={ (event) => generateCommission( event, id) }  >
                                    <GetAppIcon  fontSize="small"/>                                 
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell colSpan={6} className="history-transactions" style={{ borderBottom: 'unset'}}>
                        <Dialog
                            className={ classes.dialog }
                            maxWidth="sm"
                            BackdropProps={{ style: { backdropFilter: 'blur(2px)'} }}
                            open={ ( activeRow === index ) }
                            onClose={ () => setActiveRow(null) }
                        >
                            <DialogTitle className={ classes.dialogTitle } id="alert-dialog-title">
                                <Box component="div" display="flex" alignItems="center" justifyContent="center">
                                    <HistoryIcon fontSize="small" />
                                    <Box component="span" paddingLeft={2}>{ 'Storico'}</Box>
                                </Box>
                            </DialogTitle>
                            <DialogContent>
                                { summary.map( (transaction, index) => {
                                    return (
                                        <Box component="div" key={ `transaction-${index}` } display="flex" alignItems="center" marginBottom={2}>
                                            <Box  component="div" marginRight={3} minWidth={135}>
                                                { statusLabel(transaction.status) }
                                            </Box>
                                            <Box  component="span" display="inline-block"  marginRight={3} minWidth={220}>
                                                { `${transaction.user}` }
                                            </Box>
                                            <Box  component="span" display="inline-block">
                                                { `${format( new Date(transaction.date), "dd/MM/yyyy")}` }
                                            </Box>
                                        </Box>
                                    )
                                }
                                )}
                                {/*  */}
                            </DialogContent>
                            <DialogActions  className={ classes.DialogActions } >
                                <Button id="close" onClick={ () => setActiveRow(null) } className={ classes.button }>
                                    Chiudi
                                </Button>
                            </DialogActions>
                        </Dialog>
                        </TableCell>
                    </TableRow>
                </Fragment>
            )
        })      
    }

    const filterSelected = event => {
        const { value } = event.target;
        setFilterBy( value );
        setPage(1);
        setIsLoading( !isLoading );
    }

    const showFilter = () => {
        setFilters( !filters );
        if ( Boolean(filterBy)) {
            setFilterBy( null );
            setIsLoading( !isLoading );
        }
    }

    
    /**
     * Framer motion variants
     */
    const variants = {
        visible: { 
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.225,
                delay: 0.225,
                ease: 'easeInOut',
            }
        },
        hidden: { 
            y: -8,
            opacity: 0,
            transition: {
                duration: 0.225,
                ease: 'easeInOut',
            }
        },
    }

    const loadingState = {
        waiting: { 
            opacity: 0,
            transition: {
                duration: 1,
                ease: 'easeInOut',
            }
        },
        ready: { 
            opacity: 1,
            transition: {
                duration: 0.600,
                ease: 'easeInOut',
            }
        },
    }

    return (
        <Fragment>
            <Card className={ isClientDetail ? clsx('client',classes.root) : classes.root}>
                <AnimatePresence exitBeforeEnter>
                    <Fragment>
                        <CardContent>
                            <Grid container alignItems="center" justify="space-between" className={classes.cardTitleContainer} >
                                <Typography variant="h5" className={classes.cardTitle} >
                                    <AssignmentIcon /> <Box component="span" marginLeft={2}>Commesse</Box>
                                </Typography>
                                <Tooltip title={ `${ filters ? 'Nascondi' : 'Mostra'} filtri`} placement="top">
                                    <Button
                                        variant="text"
                                        id="filters"
                                        className={classes.button}
                                        startIcon={<FilterListIcon />}
                                        onClick={ showFilter }
                                    >
                                        Filtri
                                    </Button>
                                </Tooltip>
                            </Grid>
                            <Collapse in={ filters } className={classes.filtersContainer}>
                                <AnimatePresence exitBeforeEnter>
                                    { filters && (
                                        <Box component={ motion.div } key="filters" variants={ variants }  initial="hidden" animate="visible" exit="hidden" minWidth={240} width="20%">
                                            <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                                <InputLabel id="status-label" className={classes.inputLabel}>Stato commessa</InputLabel>
                                                <Select
                                                    labelId="status-label"
                                                    name="status"
                                                    value={( filterBy ) ?  filterBy :  STATUS_LIST[0] } 
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                        vertical: "top",
                                                        horizontal: "left"
                                                        },
                                                        transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "left"
                                                        },
                                                        getContentAnchorEl: null
                                                    }}
                                                    onChange={ filterSelected }
                                                >
                                                    { STATUS_LIST.map( (item, index ) => {
                                                        return ( <MenuItem key={index} value={ item }>{ statusLabel(item) }</MenuItem> )
                                                    })}
                                                </Select>                                                   
                                            </FormControl>
                                        </Box>
                                    )}                        
                                </AnimatePresence>
                            </Collapse>
                            { isLoading ? (
                                <Box display="flex" alignItems="center" component={ motion.div } key="LoaderCardContent" variants={ loadingState }  initial="waiting" animate="ready" exit="waiting">
                                    <CircularProgress />
                                    <Box component="div" children="Recupero lista commesse in corso..." marginLeft={3} />
                                </Box> 
                                ) : 
                                (( commissions.length ) ? (
                                    <TableContainer component={ motion.div } key="DataCardContent" variants={ loadingState }  initial="waiting" animate="ready" exit="waiting" style={{ width: '94%', margin: '0 auto' }}>
                                        <Table >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={ clsx(classes.tableHeader, classes.Col_1 )}>Codice</TableCell>
                                                    <TableCell align="left" size="small" className={ clsx(classes.tableHeader, classes.Col_2_urgent_flag )}></TableCell>
                                                    { !isClientDetail ? (
                                                        <Fragment>
                                                            <TableCell align="left" className={ clsx(classes.tableHeader, classes.Col_2 )}>Cliente</TableCell>
                                                        </Fragment>
                                                    ) : (
                                                        <Fragment>
                                                            <TableCell align="left" className={ clsx(classes.tableHeader, classes.Col_2 )}>Data di Apertura</TableCell>
                                                            <TableCell align="left" className={ clsx(classes.tableHeader, classes.Col_2_client )}>Data di Lavorazione</TableCell>
                                                            <TableCell align="left" className={ clsx(classes.tableHeader, classes.Col_2 )}>Data di Chiusura</TableCell>
                                                            <TableCell align="left" className={ clsx(classes.tableHeader, classes.Col_2 )}>Data di Rifiuto</TableCell>
                                                        </Fragment>
                                                    )}
                                                    <TableCell align="left" className={ clsx(classes.tableHeader, classes.Col_2 )}></TableCell>
                                                    <TableCell align="left" className={ clsx(classes.tableHeader, classes.Col_4 )}>Stato</TableCell>
                                                    <TableCell align="left" className={ clsx(classes.tableHeader, classes.Col_Menu )}></TableCell>
                                                    <TableCell align="left" className={ clsx(classes.tableHeader, classes.Col_Menu )}></TableCell>
                                                    <TableCell align="left" className={ clsx(classes.tableHeader, classes.Col_Menu )}></TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>                        
                                                { tableRow() }                          
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <Box component="div"> Non ci sono commesse nella lista</Box>
                                ))
                            }
                        </CardContent>
                        { ( !isLoading && ( commissions.length > 0 && totalPage > 1 )) && (
                            <CardActions className={ classes.cardActions }>
                                <Pagination 
                                    count={ totalPage } 
                                    page={ page } 
                                    color="primary" 
                                    size="medium" 
                                    onChange={ handleChangePage }
                                />
                            </CardActions>
                        )}  
                        { !isClientDetail && (
                            <Tooltip title="Aggiungi" placement="left-start">
                                <Fab color="secondary" className={ classes.fab } onClick={ () => history.push('/commesse/aggiungi') }>
                                    <AddIcon />
                                </Fab>   
                            </Tooltip> 
                        )}  
                    </Fragment>
                </AnimatePresence>
            </Card> 
            

            { isDelete.status && (
                <CommissionDelete 
                    { ...props } 
                    info={ isDelete } 
                    resetInfoToDelete={ (real) => resetInfoToDelete(real) } 
                />
            )}        
        </Fragment>
    )
}

export default Commissions;