import http from './axios.config';

export const getAll =  (param) => {
    const config = {
        headers: { "x-access-token": localStorage.getItem('token')},
        params: param
    }
    return http.get( "/clients", config );
};

export const get = ({ id }) => {
    const config = {
        headers: { "x-access-token": localStorage.getItem('token')},
    }
    return http.get( `/clients/${id}`, config );
};

export const update = (id, clientData) => {
    const config = {
        headers: { "x-access-token": localStorage.getItem('token')},
    }
    return http.put( `/clients/${id}`, clientData, config  );
};

export const create = (clientData) => {
    const config = {
        headers: { "x-access-token": localStorage.getItem('token')},
    }
    return http.post( `/clients/`, clientData, config  );
};

export const remove = id => {
    const config = {
        headers: { "x-access-token": localStorage.getItem('token')},
    }
    return http.delete(`/clients/${id}`, config);
};
