import { useState, useEffect, Fragment } from 'react';
import { useParams } from  "react-router-dom";
import { format } from 'date-fns';
import clsx from 'clsx';
import * as Commission from '../../api/commission';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {
        Box,
        Button,
        Card,
        CardContent,
        Checkbox,
        CircularProgress,
        Chip,
        Collapse,
        Dialog,
        DialogActions,
        DialogContent,
        DialogContentText,
        DialogTitle,
        Divider,
        Fab,
        FormControl,
        FormControlLabel,
        Grid,
        Hidden,
        IconButton,
        InputLabel,
        MenuItem,
        Select,
        Typography,
        Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import SaveIcon from '@material-ui/icons/Save';
import StorageIcon from '@material-ui/icons/Storage';
import UndoIcon from '@material-ui/icons/Undo';
import { fade } from '@material-ui/core/styles/colorManipulator';

import { motion, AnimatePresence } from 'framer-motion';

import ErrorSwitcher  from '../Error';


const useStyle = makeStyles( ( theme ) => ({
    root: {
        width: 'calc(94% - 2rem)',
        maxHeight: 'calc(100% - 2rem)',
        overflowY: 'auto',
        position: 'relative',
        [theme.breakpoints.down('md')]:{
            width: 'calc(100% - 2rem)',
        },
        '& .MuiFormHelperText-root': {
            position: 'relative',
            bottom: 0,
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0,
        },
        '& input.Mui-disabled': {
            color: fade(theme.palette.common.black, 0.7),
        },
        '& .MuiSelect-select.Mui-disabled': {
            color: fade(theme.palette.common.black, 0.7),
        },
        '& textarea.Mui-disabled': {
            color: fade(theme.palette.common.black, 0.7),
        },
        '& .MuiFormLabel-root': {
            fontSize: 22,
            padding: '0 4px',
            background: theme.palette.common.white,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -7px) scale(0.75)',
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: fade(theme.palette.common.black, 0.55),
        },
        '& .MuiFormControlLabel-label.Mui-disabled': {
            color: theme.palette.primary.main,
        }
    },
    button: {
        '&#save' : {
            backgroundColor: theme.palette.primary.main,
            color: '#ffddab',
            fontWeight: 'normal',
            fontSize: '0.75rem',
        },
        '&#close' : {
            fontWeight: 'normal',
            fontSize: '0.75rem',

        },
        '&#transactionHistory' : {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            fontWeight: 'normal',
            fontSize: '0.75rem',
            whiteSpace: 'nowrap',
            margin: '0 0.25rem',
        },
    },
    cardTitle: {
        marginBottom: '1.5rem',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.25rem',
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '4rem',
        },
    },
    cardTitleSection: {
        margin: '0.75rem 0 1.5rem',
        paddingLeft: 16,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            margin: '0.75rem 0 2rem'
        },
    },
    chip: {
        '&.warning': {
            background: theme.palette.warning.light,
            '& > svg': {
                color: '#96651c'
            },
        },
        '&.info': {
            background: theme.palette.info.light,
            '& > svg': {
                color: '#0062b1',
            },
        },
        '&.success': {
            background: theme.palette.success.light,
            '& > svg': {
                color: '#316932',
            },
        },
        '&.error': {
            background: theme.palette.error.light,
            '& > svg': {
                color: '#6b3434',
            },
        },
    },
    divider: {
        margin: '0.5rem 0 1rem 0',
    },
    dialog: {
        zIndex: '5000!important',
        '& .MuiDialog-paper': {
            background: '#ffddab',
        },
        '& .MuiBackdrop-root': {
            backdropFilter: 'blur(2px)',
        },
    },
    dialogTitle: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.primary.main,
        }
    },
    dialogContentText: {
        fontSize: '0.75rem',
        color: theme.palette.primary.main,

    },
    dialogActions: {
        justifyContent: 'flex-end',
    },
    editIcon: {
        fontSize: 16,
    },
    fabEditButton: {
        position: 'absolute',
        top: 16,
        right: 16,
        zIndex: 11,
        [theme.breakpoints.down('xs')]: {
            top: 48,
        },
    },
    fabSaveButton: {
        position: 'absolute',
        top: 24,
        right: 24,
        zIndex: 10,
        background: theme.palette.primary.main,
        color: theme.palette.warning.main,
        [theme.breakpoints.down('xs')]: {
            top: 58,
        },
    },
    formControl: {
        minWidth: '200px',
    },
    formControlLabel: {
            marginLeft: -8,
            width: '100%',
    },
    iconField: {
        transform: 'translateX(-8px)',
        marginRight: 8,
    },
    row: {
        '& div': {
            fontSize: '0.875rem',
            letterSpacing: '1px',
            whiteSpace: 'nowrap',
            [ theme.breakpoints.up('md')]: {
                fontSize: 16,
            },
        },
    },
    rowContainer: {
        paddingLeft: 0,
    },
    rowDates: {
        marginBottom: '1.5rem',
        padding: '0 0.25rem',
        '& .date': {
            borderRadius: 5,
            padding: 12,
            '&.created': {
                background: '#dbebf7',
                border: `1px solid ${ theme.palette.info.light }`,
                color: theme.palette.info.dark,
            },
            '&.processing': {
                background: '#fff6e9',
                border: `1px solid ${ theme.palette.warning.light }`,
                color: theme.palette.warning.dark,
            },
            '&.processed': {
                background: '#f1fff1',
                border: `1px solid ${ theme.palette.success.light }`,
                color: theme.palette.success.dark,
            },
            '&.rejected': {
                background: '#ffe0e0',
                border: `1px solid ${ theme.palette.error.light }`,
                color: theme.palette.error.dark,
            }
        },
    },
    rowHistory: {
        paddingTop: '1.2rem',
        paddingLeft: '0.25rem',
    },
}))


const CommissionDetail = ({ errorState, handleFeedback }) => {

    const classes = useStyle();
    const { id } = useParams();


    /**
    * @constructor - The model of the commission
    */
    const CommissionModel = {
        id: null,
        client_id: null,
        code: null,
        os: null,
        hardware_problem: null,
        software_problem: null,
        product_description: null,
        acquired_material: null,
        performed_operation: null,
        material_used: null,
        notes: null,
        is_erasable: false,
        is_urgent: false,
        is_paid: false,
        summary: []
    }

    /**
    * @constructor - The model of the client
    */
    const clientModel = {
        id: null,
        client_code: null,
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        address: null,
        city: null,
        postal_code: null,
    }

    /**
     * @constructor - The model of the list of the problems
     */
    const ListsProblems = {
        hardware: null,
        software: null,
    }

    /**
     * @constructor - Array of commission status 
     */
    const STATUS_LIST = [ 'created', 'processing', 'processed', 'rejected'];

    /**
     * Framer motion variants
     */
     const variants = {
        visible: { 
            x: -70,
            transition: {
                duration: 0.375,
                ease: 'easeInOut',
            }
        },
        hidden: { 
            x: 0,
            transition: {
                duration: 0.375,
                ease: 'easeInOut',
            }
        },
    }

    const loadingState = {
        waiting: { 
            opacity: 0,
            transition: {
                duration: 0.500,
                ease: 'easeInOut',
            }
        },
        ready: { 
            opacity: 1,
            transition: {
                duration: 0.500,
                ease: 'easeInOut',
            }
        },
    }

    /**
     * State - Loader status
     * @return {isLoading} - Show/hide loader
     */
    const [isLoading, setIsLoading] = useState(true);

        /**
     * State - Loader status
     * @return {isLoading} - Show/hide loader clientInfo
     */
         const [isLoadingClientDetails, setIsLoadingClientDetails] = useState(true);

    /**
     * State - Details of a commission
     * @return {commission} - The field used to list the details of commission 
     */
    const [commission, setCommission] = useState( CommissionModel );

     /**
     * State - Details of a client
     * @return {client} - The field used to list the details of client 
     */
    const [client, setClient] = useState( clientModel );

    /**
     * State - List Hardware and Software problems
     * @return {problems} - the state used to get the array list of problems
     */
    const [problems, setProblems] = useState( ListsProblems );


    const [editState, setEditState] = useState( false );
    const [editField, setEditField] = useState( null );
    const [collapseState, setCollapseState] = useState( false );
    const [modalUpdate, setModalUpdate] = useState( false );


    const getCommissionInfo = ( fromUpdate = false ) => {
        const params =  { 
            id: id
        };
        Commission.get( params )
            .then( response => {               
                setCommission( {...commission, ...response.data} );     
                setIsLoading(false);
                if( fromUpdate ) {
                    const feedback = {
                        status: true,
                        severity: 'success',
                        message:  'Commessa aggiornata con successo'
                    }      
                    handleFeedback(feedback); 
                }
            })
            .catch( error => {
                const errorMessage = ErrorSwitcher( error, 'Commission.Details');
                errorState( errorMessage );
              });
    }

    const getProblems = () => {
        if( !Boolean(problems.hardware) || !Boolean(problems.software) ) {
            Commission.getProblemsLists()
            .then( response => {
                const { hardware_problems, software_problems } = response.data;
                const lists = {
                    hardware: hardware_problems,
                    software: software_problems,
                }
                setProblems({ ...problems, ...lists });
            })
            .catch( error => {
                const errorMessage = ErrorSwitcher( error, 'Commission.Problems');
                errorState( errorMessage );
            })
        }
        return false;      
    }

    useEffect( () => {
        setIsLoading( true );
        getCommissionInfo();
        getProblems();

        // eslint-disable-next-line
    }, [])
    
    /**
     * @function handleEditState
     * @return {editState} - The state used to enable edit state
     * @return {editField} - Set the 'editField' state to null
     */
    const handleEditState = () => {
        setEditState( !editState );
        setEditField(null);
    }

    /**
     * @function handleChangeInput - Update the field of the state 'client'
     * @return {client} 
     */
    const handleChangeInput = (event, field) => {
        let { value } = event.target;
        if( !value.length ) value = null;
        setCommission( { ...commission, [field]:value })
    }

    /**
     * @function handleChangeInput - Update the field of the state 'client'
     * @return {client} 
     */
    const handleChangeSelect = (event) => {
        const { name, value } = event.target;
        setCommission( { ...commission, [name]:value })
    }


    /**
     * @function handleClose - Close the modal for update client
     * @return {modalUpdate} - Set the 'modalUpdate' state to false
     */
    const handleClose = () => {
        setModalUpdate( false )
    }


     /**
     * @function validateFields - Validate the client info 
     * @return {modalUpdate} - Show the modal for update client and set state 'modalUpdate'
     */
    const validateFields = event => {
        event.preventDefault();
        if( Boolean(commission.product_description) && Boolean(commission.acquired_material) ) {
            setModalUpdate(true);
        } else {
            const feedback = {
                status: true,
                severity: 'error',
                message:  'I campi prodotto e/o materiale acquisito sono obbligatori'
            }      
            handleFeedback(feedback);   
        }
        
    }

    /**
     * @function save - Call api update and return a Promise(response)
     * @return {editState} - Toggle the 'editState'
     * @return {editField} - Set the 'editField' state to null
     * @return {modalUpdate} - Set the 'modalUpdate' state to false
     * @return {getClientInfo} - Call api list to render the change
     * @return {handleFeedback} - Call the snackbar with {feedback} object
     */
    const save = () => {
        const { client_id, code, id, is_erasable, summary ,...updateCommission } = commission;
        setModalUpdate( false );
        const feedback = {
            status: true,
            severity: 'info',
            message:  'Salvataggio modifiche in corso...'
        }      
        handleFeedback(feedback); 
        Commission.update( id, updateCommission )
            .then( response => {
                if( response.status === 201) {
                    setEditState( !editState );
                    setEditField(null);
                    getCommissionInfo( true );  
                }             
            })
            .catch( error => {
                const errorMessage = ErrorSwitcher( error, 'Commission.Update');
                errorState( errorMessage );
            })
    }


    const infoClient = () => {
        const { client_id } = commission;
        if ( Boolean( client_id ) && !Boolean( client.id ) ) {
            const params =  { 
                id: client_id
            };
            Commission.getClientInfo( params )
                .then( response => {
                    setClient( {...client, ...response.data} );
                    setIsLoadingClientDetails(false);
                })
                .catch( error => {
                    const errorMessage = ErrorSwitcher( error, 'Client.Details');
                    errorState( errorMessage );
                });
        }
        return false;
    }

    const ClientDetails = () => {
        
        return (
            <Grid container alignItems="center" className={ classes.row }>
                <Grid item  xs={12}>
                    <Box component="div" marginBottom={2}>
                        <Box component="span" display="inline-block" minWidth={130} textAlign="left" marginRight={{ xs: 1, sm: 2 }}>{ `Codice cliente: `}</Box>
                        <Box component="span" display={{ xs: "block", sm: "inline-block" }} paddingTop={{ xs: 1,  sm: 0 }} fontWeight="bold">{ `${ client.client_code }`}</Box>
                    </Box>
                </Grid>
                <Grid item  xs={12}>
                    <Box component="div" marginBottom={2}>
                        <Box component="span" display="inline-block" minWidth={130} textAlign="left" marginRight={{ xs: 1, sm: 2 }}>{ `Nominativo: `}</Box>
                        <Box component="span" display={{ xs: "block", sm: "inline-block" }} paddingTop={{ xs: 1,  sm: 0 }} fontWeight="bold">{ `${ client.first_name } ${ client.last_name }`}</Box>
                    </Box>
                </Grid>
                <Grid item  xs={12}>
                    <Box component="div" marginBottom={2}>
                        <Box component="span" display="inline-block" minWidth={130} textAlign="left" marginRight={{ xs: 1, sm: 2 }}>{ `Indirizzo: `}</Box>
                        <Box component="span" display={{ xs: "block", sm: "inline-block" }} paddingTop={{ xs: 1,  sm: 0 }} fontWeight="bold">
                            { `${ Boolean (client.address) ? client.address : '' } ${ Boolean (client.city) ? `, ${client.city}` : '' }`}
                        </Box>
                    </Box>
                </Grid>
                <Grid item  xs={12}>
                    <Box component="div" marginBottom={2}>
                        <Box component="span" display="inline-block" minWidth={130} textAlign="left" marginRight={{ xs: 1, sm: 2 }}>{ `Email: `}</Box>
                        <Box component="span" display={{ xs: "block", sm: "inline-block" }} paddingTop={{ xs: 1,  sm: 0 }} fontWeight="bold"> 
                            { `${ Boolean (client.email) ? client.email : '' } `}
                        </Box>
                    </Box>
                </Grid>
                <Grid item  xs={12}>
                    <Box component="div" >
                        <Box component="span" display="inline-block" minWidth={130} textAlign="left" marginRight={{ xs: 1, sm: 2 }}>{ `Telefono: `}</Box>
                        <Box component="span" display={{ xs: "block", sm: "inline-block" }} paddingTop={{ xs: 1,  sm: 0 }} fontWeight="bold"> { client.phone }</Box>
                    </Box>
                </Grid>
            </Grid>
        )
    }

    /**
     * 
     * @param {String} status 
     * @returns 
     */
    const statusLabel = status => {
        switch (status) {
            case 'processed':
                return ( <Chip icon={ <CheckCircleIcon fontSize="small" />} label="Chiusa" className={ clsx('success', classes.chip) }/>) 
            case 'created':
                return ( <Chip icon={ <InfoIcon fontSize="small" />} label="Aperta" className={ clsx('info', classes.chip) }/>)
            case 'processing':
                return ( <Chip icon={ <BuildRoundedIcon fontSize="small" />} label="In lavorazione" className={ clsx('warning', classes.chip) }/>)
            case 'rejected':
                return ( <Chip icon={ <ErrorIcon fontSize="small" />} label="Rifiutata" className={ clsx('error', classes.chip) }/>)
            default:
                break;
        }
    }


    const currentSelect = list => {
        switch (list) {
            case 'hardware':
                const { hardware } = problems;
                if ( Boolean(hardware)) {
                    return  hardware.map( (item, index ) => {
                        return ( <MenuItem key={index} value={ item }>{ item }</MenuItem> )
                    })
                } 
                break;
            case 'software':
                const { software } = problems;
                if ( Boolean(software)) {
                    return  software.map( (item, index ) => {
                        return ( <MenuItem key={index} value={ item }>{ item }</MenuItem> )
                    })
                }     
                break;
            case 'status':
                return  STATUS_LIST.map( (item, index ) => {
                    return ( <MenuItem key={index} value={ item }>{ statusLabel(item) }</MenuItem> )
                })
            default:
                break;
        }
    }

    const Problems = () => {
        return (
            <Grid container item  alignItems="center"  xs={12} className={ classes.row }>
                <Grid item  xs={12} md={6}>
                    <Box component="div" paddingX={1} marginTop={2}>
                        <FormControl variant="outlined" fullWidth  className={classes.formControl} disabled={ editState ? false : true }>
                            <InputLabel 
                                id="hardware-label" 
                                children={ <Box component="span" bgcolor="white" paddingX={1}>Problema hardware</Box> }
                            />
                            <Select
                                labelId="hardware-label"
                                name="hardware_problem"
                                value={ Boolean( commission.hardware_problem ) ?  commission.hardware_problem : '' } 
                                MenuProps={{
                                    anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                    },
                                    transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                                onChange={ handleChangeSelect }
                            >
                                { currentSelect('hardware')}
                            </Select>                                                   
                        </FormControl> 
                    </Box>
                </Grid>
                <Grid item  xs={12} md={6}>
                    <Box component="div" paddingX={1} paddingTop={{ xs: 3, md: 0 }} marginTop={2}>
                        <FormControl variant="outlined" fullWidth  className={classes.formControl} disabled={ editState ? false : true }>
                            <InputLabel 
                                id="software-label" 
                                children={ <Box component="span" bgcolor="white" paddingX={1}>Problema software</Box> }
                            />
                            <Select
                                labelId="software-label"
                                name="software_problem"
                                defaultValue=""
                                value={( commission.software_problem ) ?  commission.software_problem : "" } 
                                MenuProps={{
                                    anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                    },
                                    transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                                onChange={ handleChangeSelect }
                            >
                                { currentSelect('software') }
                            </Select>                                                   
                        </FormControl>  
                    </Box>
                </Grid>
            </Grid> 
        )
    }

    const getDates = () => {
        let dates = {
            created: null,
            processing: null,
            processed: null,
            rejected: null
        }


        const foo = STATUS_LIST.map( (statusCurrent, index) => {
            return commission.summary.filter( (element) => {
                return element.status === statusCurrent;
            })
        })
        
        foo.map( (el) => {
            const length = el.length;
            if ( length ) {
                const { status, date } = el[ length - 1 ];
                dates = {...dates, [status]: format( new Date(date), "dd/MM/yyyy - H:mm:ss") }
                return dates;
            }
            return false;
        })
        return dates;

    }

    const StatusCommission = () => {       

        const dates = getDates();

        return(
            <Grid item container alignItems="center" xs={ 12 }>
                <Grid item xs={12} md={5} >
                    <Box marginBottom={ 3 } minHeight={ 70 } position="relative">
                        { ( editField === 'status') ?
                            ( <Box key="flipCardStatusEdit" display="flex"  alignItems="center" >
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <Select
                                            name="status"
                                            defaultValue=""
                                            value={( commission.status ) ?  commission.status : "" } 
                                            MenuProps={{
                                                anchorOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                                },
                                                transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                                },
                                                getContentAnchorEl: null
                                            }}
                                            onChange={ handleChangeSelect }
                                        >
                                            { currentSelect('status') }
                                        </Select>                                                   
                                    </FormControl> 
                                    <Tooltip title="Chiudi campo di modiica" placement="top">
                                        <IconButton color="primary" aria-label="modifica campo" onClick={ () => setEditField(null) } >
                                            <UndoIcon className={ classes.editIcon} />
                                        </IconButton>
                                    </Tooltip> 
                                </Box>
                            ) : (
                                <Box key="flipCardStatusRead" paddingLeft={'4px'}>
                                    <Box component="span" display="inline-block" padding={ '12px 0' } marginRight={3} >{ `Stato `}</Box>
                                    <Box component="span" display="inline-block" padding={ '12px 0' } >{ statusLabel( commission.status ) }</Box>
                                    { editState && (
                                        <Tooltip title="Apri campo di modiica" placement="top">
                                            <IconButton color="primary" aria-label="modifica campo" onClick={ () => setEditField('status') } >
                                                <CreateIcon className={ classes.editIcon} />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Box>
                            )
                        }
                        <Box >
                            <FormControlLabel
                                className={ classes.formControlLabel }
                                disabled = { editState ? false : true }
                                control={<Checkbox
                                        checked={commission.is_urgent}
                                        disableRipple
                                        color="primary"
                                        onChange={ () => setCommission( { ...commission, 'is_urgent': !commission.is_urgent }) } 
                                        />
                                    }
                                label="Urgente"
                            />
                            <FormControlLabel
                                className={ classes.formControlLabel }
                                disabled = { editState ? false : true }
                                control={<Checkbox
                                        checked={ commission.is_paid }
                                        disableRipple
                                        color="primary"
                                        onChange={ () => setCommission( { ...commission, 'is_paid': !commission.is_paid }) } 
                                        />
                                    }
                                label="Pagato"
                                
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid container >
                    <Grid item xs={12} sm={6} lg={3} className={ classes.rowDates }>
                        <Box className={ clsx( "date", "created")}>
                            <Box component="span" display="block" marginBottom={2} >Data apertura</Box>
                            <Box component="span" display="block" fontSize={16} letterSpacing={ 0.5 }>{ Boolean(dates.created) ? dates.created : 'Non disponibile' }</Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} className={ classes.rowDates }>
                        <Box className={ clsx( "date", "processing")}>
                            <Box component="span" display="block" marginBottom={2} whiteSpace="nowrap">Data lavorazione</Box>
                            <Box component="span" display="block" fontSize={16} letterSpacing={ 0.5 }>{ Boolean(dates.processing) ? dates.processing : 'Non disponibile' }</Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} className={ classes.rowDates }>
                        <Box className={ clsx( "date", "processed")}>
                            <Box component="span" display="block" marginBottom={2}>Data chiusura</Box>
                            <Box component="span" display="block" fontSize={16} letterSpacing={ 0.5 }>{ Boolean(dates.processed) ? dates.processed : 'Non disponibile' }</Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} className={ classes.rowDates }>
                        <Box className={ clsx( "date", "rejected")}>
                            <Box component="span" display="block" marginBottom={2}>Data rifiuto</Box>
                            <Box component="span" display="block" fontSize={16} letterSpacing={ 0.5 }>{ Boolean(dates.rejected) ? dates.rejected : 'Non disponibile' }</Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>    
        )
    }

    return(
        <Fragment>
            <Card className={ classes.root } >
                <AnimatePresence exitBeforeEnter>
                    <CardContent component={ motion.div } key="DataCardContent" variants={ loadingState }  initial="waiting" animate="ready" exit="waiting">
                        <ValidatorForm  onSubmit={ validateFields } instantValidate={false} >
                            <Typography variant="h5" color="primary" className={classes.cardTitle} >
                                <Box component="span" >Dettaglio Commessa { isLoading ? '' : `cod. C${ commission.code }`} </Box>
                            </Typography>
                            { isLoading ? (
                                    <Box display="flex" alignItems="center" component={ motion.div } key="LoaderCardContent" variants={ loadingState }  initial="waiting" animate="ready" exit="waiting">   
                                        <CircularProgress />
                                        <Box component="div" children="Recupero dettagli commessa in corso..." marginLeft={3} />
                                    </Box>                                        
                            ) : (
                            <Box component={ motion.div } key="DataCardContent" variants={ loadingState }  initial="waiting" animate="ready" exit="waiting">
                                <Grid container direction="column" className={ classes.rowContainer }>
                                    <StatusCommission />
                                    <Grid item xs={ 6}>
                                        <Button
                                            variant="outlined"
                                            id="transactionHistory"
                                            className={classes.button}
                                            onClick={ () => setCollapseState( !collapseState)}
                                        >
                                            Storico
                                        </Button>
                                    </Grid>
                                    <Grid container item  >
                                        <Collapse in={ collapseState } style={{ width: '100%'}} className={ classes.rowHistory }>
                                            <Box>
                                                { Boolean(commission.summary) && 
                                                    commission.summary.map( (transaction, index) =>{ 
                                                        return (
                                                            <Grid  key={index} container alignItems="center"  item xs={12} md={10} lg={8} style={{ marginBottom: ( index === commission.summary.length - 1 ) ? 0 : 16 }}>
                                                                <Grid item xs={6} sm={4}>
                                                                    <Box display="flex" alignItems="center" fontSize={{ xs: 16, sm: 20 }}>
                                                                        <CalendarTodayIcon fontSize="small" style={{ marginRight: 8 }}/>
                                                                        { format( new Date(transaction.date), "dd/MM/yyyy") }
                                                                    </Box>
                                                                    <Box display="flex" alignItems="center" marginTop={1} fontSize={{ xs: 16, sm: 20 }}>
                                                                        <QueryBuilderIcon fontSize="small" style={{ marginRight: 8 }}/>
                                                                        { format( new Date(transaction.date), "H:mm:ss") }
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={6} sm={4}>
                                                                    <Box fontSize={{ xs: 16, sm: 20 }} letterSpacing={{ xs:1, sm: 0 }}>
                                                                        { transaction.user }
                                                                    </Box>
                                                                    <Hidden smUp>
                                                                        <Box  marginTop={1} >
                                                                            { statusLabel(transaction.status) }
                                                                        </Box>
                                                                    </Hidden>
                                                                </Grid>
                                                                <Hidden xsDown>
                                                                    <Grid item xs={4}>
                                                                        { statusLabel(transaction.status) }
                                                                    </Grid>
                                                                </Hidden>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Box>
                                        </Collapse>
                                    </Grid>
                                    <Divider className={ classes.divider } variant="fullWidth" />
                                    <Grid container  className={ classes.row }>
                                        <Grid item  xs={12} > 
                                            <Box width="100%" paddingX={1} paddingBottom='0.875rem'>
                                                <Typography variant="h6" >Descrizione prodotto</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item  xs={12} md={6} style={{  minHeight: '60px' }}>
                                            <Box display="flex"  alignItems="center" >
                                                <Box width="100%" paddingX={1} >
                                                    <TextValidator  
                                                        fullWidth
                                                        disabled={ editState ? false : true }
                                                        label="Prodotto *"
                                                        InputLabelProps={{ shrink: true}}
                                                        variant="outlined"
                                                        placeholder="Inserire descrizione prodotto"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <DesktopMacIcon fontSize="small" className={ classes.iconField } />
                                                            ),
                                                            endAdornment: (
                                                                editState && (
                                                                    <Tooltip title="Cancella contenuto del campo" placement="top">
                                                                        <IconButton  color="primary" aria-label="Cancella contenuto del campo" onClick={ () => setCommission( { ...commission, 'product_description':null }) } >
                                                                            <CloseIcon className={ classes.editIcon} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                )
                                                            ),
                                                        }}
                                                        value={ Boolean(commission.product_description) ? commission.product_description : ''}
                                                        error={ Boolean(commission.product_description) ? false : true}
                                                        helperText={ Boolean(commission.product_description) ? '' : 'Campo obbligatorio' }
                                                        onChange={ (e) => handleChangeInput( e, 'product_description') } 
                                                    /> 
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item  xs={12} md={6} style={{  minHeight: '60px' }}>
                                            <Box display="flex"  alignItems="center" marginBottom={3}>
                                                <Box width="100%" paddingX={1} paddingTop={{ xs: 3, md: 0 }}>
                                                    <TextValidator  
                                                        fullWidth
                                                        disabled={ editState ? false : true }
                                                        label="Sistema operativo"
                                                        InputLabelProps={{ shrink: true}}
                                                        variant="outlined"
                                                        placeholder={ editState ? "Inserire il sistema operativo" : "" }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <StorageIcon fontSize="small" className={ classes.iconField } />
                                                            ),
                                                            endAdornment: (
                                                                editState && (
                                                                    <Tooltip title="Cancella contenuto del campo" placement="top">
                                                                        <IconButton color="primary" aria-label="Cancella contenuto del campo" onClick={ () => setCommission( { ...commission, 'os':null }) }  >
                                                                            <CloseIcon className={ classes.editIcon} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                )
                                                            ),
                                                        }}
                                                        value={ Boolean(commission.os) ? commission.os : ''}
                                                        onChange={ (e) => handleChangeInput( e, 'os') } 
                                                    /> 
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>    
                                    { ( Boolean(problems.hardware) &&  Boolean(problems.software)) && ( <Problems /> )}
                                    <Divider className={ classes.divider } variant="fullWidth" />     
                                    <Grid container alignItems="center"  className={ classes.row }>
                                        <Grid item xs={12} md={6} style={{  padding: '0 0.5rem', minHeight: 170 }}>
                                            <Box width="100%">
                                                    <Typography variant="h6" style={{ paddingBottom: '0.5rem'}}>Descrizione problemi / Note</Typography>
                                                    <TextValidator  
                                                        fullWidth
                                                        disabled={ editState ? false : true }
                                                        name="notes"
                                                        variant="outlined"
                                                        placeholder={ editState ? "Inserire note" : "" }
                                                        multiline
                                                        rows={ 5 }
                                                        value={ Boolean(commission.notes ) ? commission.notes : '' } 
                                                        onChange={ (e) => handleChangeInput( e, 'notes') } 
                                                    /> 
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6} style={{  padding: '0 0.5rem', minHeight: 170 }}>
                                            <Box width="100%">
                                                <Typography variant="h6" style={{ paddingBottom: '0.5rem'}}>Materiale acquisito *</Typography>
                                                <TextValidator  
                                                    fullWidth
                                                    disabled={ editState ? false : true }
                                                    name="acquired_material"
                                                    variant="outlined"
                                                    placeholder="Inserire materiale acquisito"
                                                    multiline
                                                    rows={ 5 }
                                                    value={ Boolean(commission.acquired_material ) ? commission.acquired_material : '' } 
                                                    error={ Boolean(commission.acquired_material) ? false : true}
                                                    helperText={ Boolean(commission.acquired_material) ? '' : 'Campo obbligatorio' }
                                                    onChange={ (e) => handleChangeInput( e, 'acquired_material') } 
                                                />  
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6} style={{  padding: '1rem 0.5rem 0 0.5rem' }}>
                                        <Box width="100%">
                                                <Typography variant="h6" style={{ paddingBottom: '0.5rem'}}>Operazioni eseguite</Typography>
                                                <TextValidator  
                                                    fullWidth
                                                    disabled={ editState ? false : true }
                                                    name="performed_operation"
                                                    variant="outlined"
                                                    placeholder={ editState ? "Inserire operazioni eseguite" : "" }
                                                    multiline
                                                    rows={ 5 }
                                                    value={ Boolean(commission.performed_operation ) ? commission.performed_operation : '' } 
                                                    onChange={ (e) => handleChangeInput( e, 'performed_operation') } 
                                                /> 
                                            </Box>
                                        </Grid>  
                                        <Grid item xs={12} md={6} style={{  padding: '1rem 0.5rem 0 0.5rem' }}>
                                            <Box width="100%">
                                                <Typography variant="h6" style={{ paddingBottom: '0.5rem'}}>Prezzi lavoro e materiali</Typography>
                                                <TextValidator  
                                                    fullWidth
                                                    disabled={ editState ? false : true }
                                                    name="material_used"
                                                    variant="outlined"
                                                    placeholder={ editState ? "Inserire materiale usato" : "" }
                                                    multiline
                                                    rows={ 5 }
                                                    value={ Boolean(commission.material_used ) ? commission.material_used : '' } 
                                                    onChange={ (e) => handleChangeInput( e, 'material_used') } 
                                                /> 
                                            </Box>
                                        </Grid>                                     
                                    </Grid>    
                                    {/* <Divider className={ classes.divider } variant="fullWidth" /> */}
                                    {/* <Box paddingLeft={1}>
                                        <Typography variant="h6" style={{ paddingBottom: '0.5rem'}}>Pagamenti</Typography>
                                        <FormControlLabel
                                            className={ clsx('isPaid', classes.formControlLabel) }
                                            disabled = { editState ? false : true }
                                            control={<Checkbox
                                                    checked={ commission.is_paid }
                                                    disableRipple
                                                    color="primary"
                                                    onChange={ () => setCommission( { ...commission, 'is_paid': !commission.is_paid }) } 
                                                    />
                                                }
                                            label="Pagato"
                                            labelPlacement="start"
                                        />
                                    </Box> */}
                                    <Divider className={ classes.divider } variant="fullWidth" />
                                    <AnimatePresence exitBeforeEnter>
                                        <Box paddingX={2} component={ motion.div } key="DataClientDetails" variants={ loadingState }  initial="waiting" animate="ready" exit="waiting">
                                            <Typography variant="h6" color="primary" className={classes.cardTitleSection} style={{ marginTop: 0, paddingLeft: 0, }}>
                                                <Box component="span"> { 'Anagrafica cliente '}</Box>
                                            </Typography>
                                            { infoClient() }
                                            { isLoadingClientDetails ? (
                                                <Box display="flex" alignItems="center" component={ motion.div } key="LoaderCardContentClientDetail" variants={ loadingState }  initial="waiting" animate="ready" exit="waiting">   
                                                    <CircularProgress />
                                                    <Box component="div" children="Recupero dettagli cliente in corso..." marginLeft={3} />
                                                </Box>
                                            ) : (
                                                <ClientDetails />   
                                            )}
                                        </Box>
                                    </AnimatePresence>
                                </Grid>
                                <Tooltip title={ editState ? "Disabilita modifiche" : "Abilita modifiche"} placement="top">
                                    <Fab color="secondary" className={ classes.fabEditButton } aria-label="Abilita modifiche utente" onClick={ handleEditState }>
                                        { editState ?  <LockOpenIcon />: <LockIcon />}
                                    </Fab>
                                </Tooltip>
                                <AnimatePresence exitBeforeEnter>
                                    { editState && ( 
                                        <Tooltip title="Salva" placement="top">         
                                            <Fab color="secondary" size="small" type="submit" component={ motion.button } variants={ variants }  initial="hidden" animate="visible" exit="hidden" className={ classes.fabSaveButton } aria-label="salva modifiche commessa" >
                                                <SaveIcon />
                                            </Fab>   
                                        </Tooltip>          
                                    )}  
                                </AnimatePresence>    
                            </Box>                 
                            )}
                        </ValidatorForm>
                    </CardContent> 
                </AnimatePresence>
            </Card>
            <Dialog
                className={ classes.dialog }
                open={ modalUpdate }
                onClose={ handleClose }
            >
                <DialogTitle className={ classes.dialogTitle } id="alert-dialog-title">
                    <Box component="span" marginLeft={0}>{ 'Attenzione'}</Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={ classes.dialogContentText } id="alert-dialog-description">
                        <Box component="span" >{ 'Sei sicuro di voler aggiornare le informazioni della commessa?'}</Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={ classes.dialogActions }>
                    <Button id="save" onClick={ save } className={ classes.button }>
                        Salva
                    </Button>
                    <Button id="close" onClick={ handleClose } className={ classes.button }>
                        Chiudi
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default CommissionDetail;