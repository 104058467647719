import onLogin from './login';
import onGet from './details';

const ErrorUser = errorDetails => {
    const { method, status } = errorDetails;

    switch ( method ) {
        case 'Login':
            return onLogin( status );
        case 'Details':
            return onGet( status );
        default:
            return null;
    }
}

export default ErrorUser;