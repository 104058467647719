import http from './axios.config';

/**
 * Create a commission
 * @param {*} clientData 
 */
export const create = (clientData) => {
    const config = {
        headers: { "x-access-token": localStorage.getItem('token')},
    }
    return http.post( `/commissions/`, clientData, config  );
};

/**
 * The list of commissions
 * @param {*} params 
 */
export const getAll =  (param) => {
    const config = {
        headers: { "x-access-token": localStorage.getItem('token')},
        params: param,
    }
    return http.get( "/commissions", config);
};

/**
 * Get the list of problems hw and sw
 */
export const getProblemsLists = () => {
    const config = {
        headers: { "x-access-token": localStorage.getItem('token')},
    }
    return http.get( "/commissions/static/data", config );
}

/**
 * Search clients
 * @param {*} clientData 
 */
export const searchClient = (clientData) => {
    const config = {
        headers: { "x-access-token": localStorage.getItem('token')},
    }
    return http.post( `/clients/search`, clientData, config  );
};

/**
 * Get client info by id
 * @param {*} param0 
 */
export const getClientInfo = ({ id }) => {
    const idClient =  (typeof id === 'string') ? id : id.id;
    const config = {
        headers: { "x-access-token": localStorage.getItem('token')},
    }
    return http.get( `/clients/${idClient}`, config );
};


/**
 * Get a details of a commission
 * @param {*} param0 
 */
export const get = ({ id }) => {
    const config = {
        headers: { "x-access-token": localStorage.getItem('token')},
    }
    return http.get( `/commissions/${id}`, config );
};

/**
 * Generate pdf commission
 * @param {*} param0 
 */
 export const generatePdf = ( id ) => {
    const config = {
        headers: { "x-access-token": localStorage.getItem('token')},
        responseType: 'blob',
    }
    return http.get( `/commissions/${id}/pdf`, config );
};

/**
 * Update a commission
 * @param {*} param0 
 */
export const update = (id, commissionData) => {
    const config = {
        headers: { "x-access-token": localStorage.getItem('token')},
    }
    return http.put( `/commissions/${id}`, commissionData, config  );
};

/**
 * Delete a commission
 * @param {*} param0 
 */
export const remove = id => {
    const config = {
        headers: { "x-access-token": localStorage.getItem('token')},
    }
    return http.delete(`/commissions/${id}`, config);
};