import { Fragment, useState, useEffect, useMemo, useCallback } from 'react';
import * as User from '../../api/login';
import jwt_decode from "jwt-decode";
import clsx from 'clsx';
import debounce from 'lodash.debounce';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment'; 
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import * as PALETTE from '../../style/material-ui/Palette';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Sidebar from './Sidebar';
import { fade } from '@material-ui/core/styles/colorManipulator';

import Logo from '../../images/logo.svg';


const StyledMenu = withStyles({
    paper: {
        background: PALETTE.PRIMARY,
        border: `1px solid ${ PALETTE.PRIMARY }`,
        color: 'white',
        '& ul': {
            padding: 0,
            '& > li': {
                '&:first-of-type': {
                    height: 0,
                    paddingTop: 0,
                    paddingBottom: 0,
                    "@media (max-width:960px)":{
                        paddingTop: 6,
                        paddingBottom: 6,
                        height: 'auto',
                    },
                },
                '&:not(:first-of-type):hover': {
                    background: '#3a5963',
                    transition: 0.3,
    
                },
            },
        },
    },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const useStyle = makeStyles( ( theme ) => ({
    appBar: {
        zIndex: 1500,
    },
    editIcon: {
        fontSize: 16,
    },
    searchClient: {
        background: theme.palette.common.white,
        marginLeft: 16,
        width: 200,
        height: 35,
        '& > div': {
            width: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            paddingLeft: 34,
            width: '100%',
            height: 0,
            marginLeft: 0,
            zIndex: 300,
            overflow: 'hidden',
            transition: theme.transitions.create("height", {duration: theme.transitions.duration.standard}),
            '& > div': {
                width: 'calc(100% - 100px)',
                marginRight: 8,
            },
            '&.active': {
                height: 'calc(100% - 16px)',
                paddingTop: 12,
                paddingBottom: 4,
                transition: theme.transitions.create("height", {duration: theme.transitions.duration.standard}),
            }
        },
    },
    toolbar: {
        background: theme.palette.common.white,
        paddingRight: 32,
        [theme.breakpoints.down('sm')]: {
            paddingRight: 12,
        },
        '& #topbar__logo': {
            width: 200,
            height: 64,
            background: `url(${Logo})`,
            backgroundSize: '80% 80%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            [theme.breakpoints.down('sm')]: {
                width: 150,
                backgroundSize: '90% 90%',
            },
            [theme.breakpoints.down('xs')]: {
                width: 120,
                backgroundSize: '100% 100%',
            },
        },
        '& .topbar__hamburgerIcon__container': {
            borderLeft: `1px solid ${ fade(theme.palette.primary.light, 0.25 )}`,
            borderRight: `1px solid ${ fade(theme.palette.primary.light, 0.25 )}`,
        },
        '& #topbar__userProfile': {
            marginLeft: 'auto',
            marginRight: 4,
            [theme.breakpoints.down('xs')]: {
                marginLeft: 'unset',
            },
        },
    }
}));

const Topbar = (props) => {

    const classes = useStyle();

    const { handleAuthenticationState, currentRoute, getStateSidebar, token, searching } = props;

    /**
     * @constructor - The model of the search of a client
     */
    const searchInitial = {
        field: null,
        requestBy: null,
        value: null
    }
    
    /**
     * State - Search client
     * @return {search} - the state used to handle search client
     */
    const [search, setSearch] = useState( searchInitial );

    /**
     * State - Search client is showed
     * @return {searchIsShow} - the state used to view/hide search client input
     */
     const [searchIsShow, setSearchIsShow] = useState( false );

    /**
     * State - User info
     * @return {search} - the state used to handle user details
     */
     const [user, setUser] = useState( null );


    const [profileMenu, setProfileMenu] = useState(null);
    const [isOpen, setIsOpen] = useState( true );
    const [isMobileOpen, setIsMobileOpen] = useState( false );


    const toggleMenu = event => {
        setProfileMenu(event.currentTarget);
    }

    const closeProfileMenu = () => {
        setProfileMenu(null);
    }

    const logout = () => {
        handleAuthenticationState( false );
    }

    const isAList = () => {
        console.log( currentRoute === 'commissions', currentRoute === 'clients' );
        if( currentRoute === 'commissions' ||  currentRoute === 'clients'  ) {
            return true;
        }
        return false;
    }

    const isClients = () => {
        return ( currentRoute === 'clients');
    }

    const getUser = tokenEncoded => {
        var decoded = jwt_decode(tokenEncoded);
        const { id } = decoded;
        User.get(id)
            .then( response => {
                setUser({ ...user, ...response.data});
            })
            .catch( error => {
                // console.error(error.response);
            })
    }   

    useEffect( () => {
        getUser(token);

        return () => {
            setSearch({ ...search, ...searchInitial });
            searching(null);  
        }

    // eslint-disable-next-line
    }, [currentRoute]);

    const Usernames = () => {
        const isUserExists = Boolean(user);
        if  ( !isUserExists ) {
           return false; 
        }
        return(
            <Box component ="div">
                { user.first_name + ' ' + user.last_name }
            </Box>
        )

    }

    const UserProfileMenu = props => {
        return(
            <StyledMenu
                { ...props }
                style = {{ zIndex: 2000 }}
                anchorEl = { profileMenu }
                open = { Boolean( profileMenu ) }
                onClose = { closeProfileMenu }
            >
                <MenuItem 
                    button={false}
                    children={ <> <Box component="div" fontSize={12} letterSpacing={1}><Usernames/></Box></> }
                />
                <MenuItem 
                    children={ <> <ExitToAppIcon fontSize="small"/><Box component="span" paddingLeft={2} fontSize={14} letterSpacing={1}>Logout</Box></> }
                    onClick={ logout }
                />
            </StyledMenu>
        )
    };

    const openSidebar = event => {
        if ( event.currentTarget.classList.contains('mobile') ) {
            setIsMobileOpen( !isMobileOpen );
        } else {
            setIsOpen( !isOpen ); 
            getStateSidebar(isOpen);
        }
    }

    const closeMobileSidebar = () => {
        setIsMobileOpen( !isMobileOpen );
    }

    /**
     * @function searchClient - Handle the search of the client in autocomplete wizard creation
     * @return {setSearch} - set the search params
     * @return {startSearch} - start the search in the next @function startSearch
     */
    const searchClient = event => {
        const { value } = event.target;

        if( Boolean( value )) {
            const searchCurrent = {
                requestBy: currentRoute,
                field: ( isNaN( value ) ? 'name' : 'client_code' ) ,
                value: value,
            }
            if ( !isNaN( value ) && currentRoute !== 'commissions' ) {
                setSearch({ ...search, ...searchCurrent}, startSearchDebounced(searchCurrent));
            } else {
                if (value.length >= 3) {
                    setSearch({ ...search, ...searchCurrent}, startSearchDebounced(searchCurrent));
                } else {
                    setSearch({ ...search, ...searchCurrent}, startSearch(null));
                }
            }
        } else {
            setSearch({ ...search, ...searchInitial}, startSearch(null));
        }    
    }

    const startSearch = useCallback( param => {
        searching(param);      
    }, [searching]);

    const startSearchDebounced = useMemo( () => debounce(
        (param) => startSearch(param), 300), [startSearch] 
    )

    const isSearching = Boolean(search.field);


    return(
        <Fragment>
            <AppBar id="topbar" className={ classes.appBar } position="fixed" color="transparent">          
                <Toolbar className={ classes.toolbar } disableGutters>
                    <div id="topbar__logo"></div>
                    <Hidden smDown >
                        <Box className="topbar__hamburgerIcon__container" component="div" p={ '0 0.25rem '}>
                            <IconButton color="primary" aria-label="open sidebar" onClick={ openSidebar }>
                                <MenuIcon />
                            </IconButton>
                        </Box>
                    </Hidden>
                    <Hidden mdUp >
                        <Box className="topbar__hamburgerIcon__container" component="div" p={ '0 0.25rem '}>
                            <IconButton className="mobile" color="primary" aria-label="open sidebar" onClick={ openSidebar }>
                                <MenuIcon />
                            </IconButton>
                        </Box>
                    </Hidden>

                    { isAList() && (
                        <>
                            <Box component="div" className={ searchIsShow ? clsx('active', classes.searchClient) : classes.searchClient } >
                                <TextField  
                                    fullWidth 
                                    variant="standard" 
                                    id="topbar__search_client" 
                                    name="topbar__search_client" 
                                    placeholder={`Ricerca ${ currentRoute === 'clients' ? 'clienti' : 'commesse'}`}
                                    value={ isSearching ? search.value : '' }
                                    InputProps={{
                                        endAdornment: !isSearching ?    
                                            <InputAdornment position="start">
                                                <SearchIcon size="small" className={ classes.editIcon }/>
                                            </InputAdornment>
                                            :
                                            <IconButton size="small" id="topbar__deleteSearch" color="primary" aria-label="cancella ricerca cliente" disableRipple disableFocusRipple onClick={ () => setSearch({ ...search, ...searchInitial}, startSearch(null)) }  >
                                                <CloseIcon className={ classes.editIcon }/>
                                            </IconButton>
                                    }}
                                    onChange={ searchClient }
                                />  
                                <Hidden smUp>
                                    <IconButton id="topbar__toggleSearch" color="primary" aria-label="apri ricerca cliente" disableRipple disableFocusRipple onClick={ () => setSearchIsShow( !searchIsShow, setSearch({ ...search, ...searchInitial}, startSearch(null)) ) } >
                                        <ArrowForwardIosIcon />
                                    </IconButton>
                                </Hidden>
                            </Box>
                            <Hidden smUp>
                                <Box marginLeft="auto" marginRight={1}>
                                    <IconButton id="topbar__toggleSearch" color="primary" aria-label="apri ricerca cliente" disableRipple disableFocusRipple onClick={ () => setSearchIsShow( !searchIsShow, setSearch({ ...search, ...searchInitial}, startSearch(null)) ) } >
                                        <SearchIcon />
                                    </IconButton>
                                </Box>
                            </Hidden>
                        </>
                    )}
                        

                    <IconButton id="topbar__userProfile" color="primary" aria-label="logout user" onClick={ toggleMenu } className={ isClients() ? 'client' : '' }>
                        <AccountCircle />
                    </IconButton>
                    <Hidden smDown>
                        <Typography variant="subtitle2"><Usernames /></Typography>
                    </Hidden>
                </Toolbar>          
            </AppBar>
            <UserProfileMenu id="userProfileMenu" />
            <Sidebar 
                stateSidebar = { isOpen } 
                stateMobileSidebar = { isMobileOpen }
                closeMobileSidebar = { closeMobileSidebar }
            />
        </Fragment>

    )
}

export default Topbar;