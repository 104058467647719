const onDelete = ( status ) => {

    switch ( status ) {
        case 404:
            return {
                status: status,
                message: "cancellare la commessa: la commessa non esiste",
            }    
        case 409:
            return {
                status: status,
                message: "cancellare la commessa: la commessa non può essere cancellata",
            } 
        case 422:
            return {
                status: status,
                message: "cancellare la commessa: l' uuid commessa non è corretto",
            } 
        default:
            return null;
    }

}

export default onDelete;