import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import clsx from 'clsx';
import jwt_decode from "jwt-decode";
import * as User from '../api/login';
import {
        Box,
        Button,
        Grid,
        TextField,
        Typography,
    } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Logo from '../images/logo.svg';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { AnimatePresence, motion } from 'framer-motion';
import ErrorSwitcher from './Error';


const useStyle = makeStyles( (theme) => ({
    errorContainer: {
        marginTop: '1rem',
    },
    alertError: {
        fontSize: '0.75rem',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.error.contrastText,
        background: theme.palette.error.main,
        '& svg': {
            color: theme.palette.error.contrastText,
        },
    },
    alertInfo: {
        fontSize: '0.75rem',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.info.contrastText,
        background: theme.palette.info.main,
        '& svg': {
            color: theme.palette.info.contrastText,
        },
    },
    iconField: {
        transform: 'translateX(-8px)',
        marginRight: 8,
    },
    input: {
        marginBottom: 42,
        '& input': {
            fontSize: 16,
        },
    },
    loginButton: {
        margin: '0 auto',
        '&.Mui-disabled': {
            
        },
    },
    loginButtonContainer: {
        '&.disabled': {
            cursor: 'not-allowed',
        },
    },
    loginContainer: {
        background: fade(theme.palette.common.white, 0.8),
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 8,
        [theme.breakpoints.down('xs')]: {
            right: 0,
        },
    },
    typographyH6: {
        marginTop: 48,
        marginBottom: 28,
        fontSize: 18,
        textAlign: 'center',
    }
}))

const Login = ( props ) => {

    const classes = useStyle();

    const token = localStorage.getItem('token');
    const isLogged = Boolean( token );

    const initialLogInfo = {
        email: '',
        password: ''
    }

    const location = useLocation();
    const history = useHistory();    

    const { handleAuthenticationState, errorState, logout } = props;

    const [isLoadingLogin, setIsLoadingLogin] = useState(false);

    /**
     * State - User info
     * @return {search} - the state used to  to handle user details
     */
    const [user, setUser] = useState( null );

    const [errorAuthentication, setErrorAuthentication] = useState(false);
    const [errorAuthorization, setErrorAuthorization] = useState(false);
    const [logInfo, setLogInfo] = useState(initialLogInfo);

    const userLogged = ( token ) => {
        const  { id } = jwt_decode(token);

        User.get(id)
            .then( response => {
                setUser({ ...user, ...response.data});
            })
            .catch( error => {
                const errorMessage = ErrorSwitcher( error, 'User.Details');
                errorState( errorMessage );
                setUser(null);
            })
    }

    useEffect( () => {
        if( isLogged ) userLogged(token);

        const isErrorAuth = () => {
            if( typeof location.state !== 'undefined' ) {
               if( location.state.errorAuthorization ) {
                    history.replace({ ...location.state, state: undefined});
                    return setErrorAuthorization( true );
                }
            }
        }
        
        isErrorAuth();
    
    // eslint-disable-next-line
    }, [])


    const getLogin = event => {
        event.preventDefault();
        if( Boolean(logInfo.email) && Boolean(logInfo.password) ) {
            if( errorAuthentication ) setErrorAuthentication( false );
            if( errorAuthorization ) setErrorAuthorization( false );
            setIsLoadingLogin( true );
            
            User.logging(logInfo)
                .then( response => {
                    const { status, data } = response;
                    if ( status === 200 ) {
                        handleAuthenticationState(data);
                    }
                })
                .catch( error => {
                    if ( typeof error.response !== 'undefined' ) {
                        const { status: statusError } = error.response;
                        if ( statusError === 401 ) {
                            setIsLoadingLogin( false );
                            setErrorAuthentication( true );
                            return null;
                        }  
                    }  
                    const errorMessage = ErrorSwitcher( error, 'User.Login');
                    errorState( errorMessage );
                })

            setErrorAuthorization(false);
        }
    }

    const changeInput = event => {
        const { name, value } = event.target;
        setLogInfo({ ...logInfo, [name]: value })
    }

    const UserLogged = () => {
        if( Boolean(user) ) {

            const { first_name, last_name } = user;
            return(
                <Box marginTop={3}  overflow="hidden">
                    <Box  display="flex" flexDirection="column" alignItems="center" component={ motion.div } key="loggedUser" variants={ variants } initial="hidden" animate="visible" exit="hidden">
                        <Box >
                            { `Salve, ${ first_name } ${ last_name }`}
                        </Box>
                        <Box display="inline-block" marginTop={3} >
                            <Box display="flex" flexDirection="column" >
                                <Button 
                                    onClick={ () => history.push('/commesse/') }
                                    startIcon={ <AssignmentIcon fontSize="small"/> }
                                >
                                    Commesse
                                </Button>
                                <Button 
                                    onClick={ () => logout()}
                                    startIcon={ <ExitToAppIcon fontSize="small"/> }
                                >
                                    Logout
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )
        }
        return null;
    }

    /**
     * Framer motion variants
     */
    const variants = {
        visible: { 
            y: 0,
            transition: {
                duration: 0.6,
                ease: 'easeInOut',
            }
        },
        hidden: { 
            y: '100%',
            transition: {
                duration: 0.6,
                ease: 'easeInOut',
            }
        },
    }

    return (
        <div className="login__container">
            <div className="login__background__image"></div>
            <Grid container justify ="center" alignItems="center" item lg={3} md={5} sm={7} xs={12} className={ classes.loginContainer }>
                <Box  width={{ xs: '80%'}} height={ isLogged ? 248 : 'unset' } overflow="hidden" style={{ transition: 'height 0.3s'}}>
                    <Box textAlign="center"  className={ classes.loginLogo } component={ motion.div } initial={{ y: '-100%'}} animate={{ y: 0 }} transition={{ duration: 0.5}} >
                        <img src={ Logo } alt="Logo" width='70%' height='100%' />
                    </Box>
                    <AnimatePresence exitBeforeEnter>
                        { isLogged ? (
                            <UserLogged />
                            ) : (
                            <Box key="noLoggedUser" component={ motion.div } variants={ variants } initial="hidden" animate="visible" exit="hidden">
                                <Typography variant="h6"  className={ classes.typographyH6 }>Accedi ai servizi</Typography>
                                <form className="" autoComplete="off"  onSubmit={ getLogin }>
                                    <TextField  
                                        autoComplete="off"
                                        className={ classes.input }
                                        fullWidth 
                                        id="email" 
                                        InputProps={{
                                            startAdornment: (
                                                <AccountCircle fontSize="small" className={ classes.iconField } />
                                            ),
                                        }}
                                        name="email" 
                                        label="Email*" 
                                        defaultValue="" 
                                        onChange={ changeInput } 
                                        placeholder="Inserire email"
                                        variant="outlined"
                                    />  
                                    <TextField 
                                        autoComplete="off"
                                        className={ classes.input }
                                        fullWidth 
                                        type="password" 
                                        id="password" 
                                        InputProps={{
                                            startAdornment: (
                                                <VpnKeyIcon fontSize="small" className={ classes.iconField }/>
                                            ),
                                        }}
                                        name="password" 
                                        label="Password*" 
                                        defaultValue=""
                                        onChange={ changeInput } 
                                        placeholder="Inserire password"
                                        variant="outlined"
                                    /> 
                                    <Box display="flex" justifyContent="center" marginBottom={3}>
                                        <Box className={ ( Boolean(logInfo.email) && Boolean(logInfo.password) ) ? classes.loginButtonContainer : clsx('disabled', classes.loginButtonContainer) }>
                                            <Button 
                                                fullWidth
                                                variant="contained" 
                                                color="secondary" 
                                                type="submit" 
                                                disabled={ ( Boolean(logInfo.email) && Boolean(logInfo.password) ) ? false : true }
                                                className={ classes.loginButton }
                                            >
                                                Accedi
                                            </Button> 
                                        </Box>
                                    </Box>
                                </form>    
                                { ( (errorAuthentication) || (errorAuthorization) || isLoadingLogin) && (
                                    <Box component="div" className={ classes.errorContainer}>
                                        { (errorAuthentication) && <Alert severity="error" variant="outlined" className={ classes.alertError } >{ 'Username o password errata'}</Alert> }       
                                        { (errorAuthorization) && <Alert severity="error" variant="outlined" className={ classes.alertError } >{ 'Esegui il login per accedere ai servizi'}</Alert> } 
                                        { (isLoadingLogin) && <Alert severity="info" variant="outlined"  className={ classes.alertInfo }>{ 'Autenticazione in corso...'}</Alert> }             
                                    </Box>
                                )}
                            </Box>
                        )}
                    </AnimatePresence>
                </Box>           
            </Grid>
        </div>
    )
}

export default Login;