import http from './axios.config';

/**
 * Login
 * @param {*} userData 
 */
 export const logging = (userData) => {
    return http.post( `/users/login`, userData  );
};

/**
 * Login
 * @param {*} userData 
 */
export const get = (id) => {
    const config = {
        headers: { "x-access-token": localStorage.getItem('token')},
    }
    return http.get( `/users/${id}`, config  );
};