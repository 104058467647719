import ErrorCommission from './commission';
import ErrorClient from './client';
import ErrorUser from './user';

const ErrorSwitcher = ( error, source ) => {
    
    if( typeof error.response === 'undefined' ) 
        return {
            status: null,
            message: "il server è offline",
        };

    const { status } = error.response;

    if( status === 401 ) 
        return {
            status: status,
            message: "eseguire l' operazione: sessione scaduta.",
            redirectRoute: '/'
        };

    if( status === 500 ) 
        return {
            status: status,
            message: "errore di sistema",
        };
    
    const details = {};

    const [ component, method ] = source.split( "." );
    details[ 'method' ] = method;
    details[ 'status' ] = status;

    switch ( component ) {
        case 'Client':
            return ErrorClient( details );
            
        case 'Commission':
            return ErrorCommission( details );
        case 'User':
            return ErrorUser( details );
        default:
            return null;
    }

}

export default ErrorSwitcher;