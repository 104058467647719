const onCreate = ( status ) => {

    switch ( status ) {
        case 404:
            return {
                status: status,
                message: "creare la commessa: il cliente non esiste",
            } 
        case 422:
            return {
                status: status,
                message: "creare la commessa: errore nei parametri di richiesta",
            } 
        default:
            return null;
    }

}

export default onCreate;