import onDelete from './delete';
import onList from './list';
import onCreate from './create';
import onGet from './details';
import onUpdate from './update';

const ErrorClient = errorDetails => {
    const { method, status } = errorDetails;

    switch ( method ) {
        case 'Add':
            return onCreate( status );
        case 'Delete':
            return onDelete( status );
        case 'Details':
            return onGet( status );
        case 'List':
            return onList( status );           
        case 'Update':
            return onUpdate( status );      
        default:
            return null;
    }
}

export default ErrorClient;